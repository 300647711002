
import React, {useEffect} from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import AOS from 'aos';
import 'aos/dist/aos.css';

import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import ServicesAndLocations from "./pages/Services/ServicesAndLocations";
import LandscapeMaintenance from "./pages/Services/LandscapeMaintenance";
import CommercialLandscapeMaintenance from "./pages/Services/LandscapeMaintenance/CommercialLandscapeMaintenance";
import ResidentialLandscapeMaintenance from "./pages/Services/LandscapeMaintenance/ResidentialLandscapeMaintenance";
import StrataLandscapeMaintenance from "./pages/Services/LandscapeMaintenance/StrataLandscapeMaintenance ";
import ResidentialLawnCare from "./pages/Services/ResidentialLawnCare";
import ChaferBeetleControl from "./pages/Services/ChaferBeetleControl";
import LeafCleanup from "./pages/Services/GardenCleanups/LeafCleanup";
import GardenCleanups from "./pages/Services/GardenCleanups";
import PruningShearing from "./pages/Services/GardenCleanups/GardenCleanup/PruningShearing";
import BambooCleanup from "./pages/Services/GardenCleanups/GardenCleanup/BambooCleanup";
import BlackberryRemoval from "./pages/Services/GardenCleanups/GardenCleanup/BlackberryRemoval";
import GardenCleanup from "./pages/Services/GardenCleanups/GardenCleanup";
import HardscapeInstallsMaintenance from "./pages/Services/HardscapeInstallsMaintenance";
import SoftscapeInstallsMaintenance from "./pages/Services/SoftscapeInstallsMaintenance";
import DesignAndModelling from "./pages/Services/DesignAndModelling";
import SnowManagement from "./pages/Services/SnowManagement";
import EFleet from './pages/EFleet';
import Projects from "./pages/Projects";
import PavingStone from "./pages/Projects/PavingStone";
import Design3d from "./pages/Projects/Design3d";
import RetainingWall from "./pages/Projects/RetainingWall";
import Plantings from "./pages/Projects/Plantings";
import OneTimeCleanups from "./pages/Projects/OneTimeCleanups";
import TurfInstalls from "./pages/Projects/TurfInstalls";
import ContactUs from "./pages/ContactUs";
import JoinOurTeam from "./pages/JoinOurTeam";
import Blog from "./pages/Blog";
import FertilizerBlog from "./pages/Blog/FertilizerBlog";
import PavingStoneBlog from "./pages/Blog/PavingStoneBlog";
import BeesBlog from "./pages/Blog/BeesBlog";
import ChaferBeetleBlog from "./pages/Blog/ChaferBeetleBlog";
import SeasonalGuideBlog from "./pages/Blog/SeasonalGuideBlog";
import WateringLawnBlog from "./pages/Blog/WateringLawnBlog";
import PollinatorBlog from "./pages/Blog/PollinatorBlog";
import FallLeafCleanupBlog from "./pages/Blog/FallLeafCleanupBlog";
import BlackberryRemovalBlog from "./pages/Blog/BlackberryRemovalBlog";
import CuttingPerennialsBlog from "./pages/Blog/CuttingPerennialsBlog";
import LateFallGardeningBlog from "./pages/Blog/LateFallGardeningBlog";
import SoilAndMulchBlog from "./pages/Blog/SoilAndMulchBlog";
import SnowIceManagementBlog from './pages/Blog/SnowIceManagementBlog';
import ReplacingTimberwallBlog from './pages/Blog/ReplacingTimberwallBlog';
import ExpertWalkwayBlog from './pages/Blog/ExpertWalkwayBlog';
import SpringLawnBlog from './pages/Blog/SpringLawnBlog';
import RedThreadBlog from './pages/Blog/RedThredBlog';
import SustainableLandscapingBlog from "./pages/Blog/SustainableLandscapingBlog";
import CedarTimberBlog from "./pages/Blog/CedarTimberBlog";
import CreatingYourOutdoorBlog from "./pages/Blog/CreatingYourOutdoorBlog";
import AeratingYourLawnBlog from "./pages/Blog/AeratingYourLawnBlog";
import FertilizerLabelBlog from "./pages/Blog/FertilizerLabelBlog";
import SpringGardenBlog from "./pages/Blog/SpringGardenBlog";
import AllElectricBlog from "./pages/Blog/AllElectricBlog";
import Vancouver from "./pages/ServiceArea/Vancouver";
import NorthVancouver from "./pages/ServiceArea/NorthVancouver";
import WestVancouver from "./pages/ServiceArea/WestVancouver";
import Burnaby from "./pages/ServiceArea/Burnaby";
import Richmond from "./pages/ServiceArea/Richmond";
import PittMeadows from "./pages/ServiceArea/PittMeadows";
import NewWestminster from "./pages/ServiceArea/NewWestminster";
import Coquitlam from "./pages/ServiceArea/Coquitlam";
import PortCoquitlam from "./pages/ServiceArea/PortCoquitlam";
import PortMoody from "./pages/ServiceArea/PortMoody";
import MapleRidge from "./pages/ServiceArea/MapleRidge";
import Delta from "./pages/ServiceArea/Delta";
import Surrey from "./pages/ServiceArea/Surrey";
import WhiteRock from "./pages/ServiceArea/WhiteRock";
import WestVancouverLawnMowing from "./pages/LandingPages/WestVancouverLawnMowing";
import NorthVancouverLawnMowing from "./pages/LandingPages/NorthVancouverLawnMowing";
import VancouverLawnMowing from "./pages/LandingPages/VancouverLawnMowing";
import NotFound from "./pages/NotFound";

export default function App() {

  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_TAG_KEY,
  };
  TagManager.initialize(tagManagerArgs);

  const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
      mirror: false,
      disable: false
    });
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {/* Main Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/our-projects" element={<Projects />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/join-our-team" element={<JoinOurTeam />} />
        <Route path="/blog" element={<Blog />} />
        {/* Services Routes */}
        <Route
          path="/services/services-and-locations"
          element={<ServicesAndLocations />}
        />
        <Route
          path="/services/landscape-maintenance"
          element={<LandscapeMaintenance />}
        />
        <Route
          path="/services/landscape-maintenance/commercial-landscape-maintenance"
          element={<CommercialLandscapeMaintenance />}
        />{" "}
        <Route
          path="/services/landscape-maintenance/residential-landscape-maintenance"
          element={<ResidentialLandscapeMaintenance />}
        />{" "}
        <Route
          path="/services/landscape-maintenance/strata-landscape-maintenance"
          element={<StrataLandscapeMaintenance />}
        />
        <Route
          path="/services/residential-lawn-care"
          element={<ResidentialLawnCare />}
        />
        <Route
          path="/services/hardscape-installs-maintenance"
          element={<HardscapeInstallsMaintenance />}
        />
        <Route
          path="/services/softscape-installs-maintenance"
          element={<SoftscapeInstallsMaintenance />}
        />
        <Route
          path="/services/design-and-modelling"
          element={<DesignAndModelling />}
        />
        <Route path="/services/snow-management" element={<SnowManagement />} />
        <Route
          path="/services/european-chafer-beetle-control"
          element={<ChaferBeetleControl />}
        />
        <Route path="/services/garden-cleanups" element={<GardenCleanups />} />
        <Route
          path="/services/garden-cleanups/leaf-cleanup"
          element={<LeafCleanup />}
        />
        <Route
          path="/services/garden-cleanups/garden-cleanup"
          element={<GardenCleanup />}
        />
        <Route
          path="/services/garden-cleanups/garden-cleanup/pruning-shearing"
          element={<PruningShearing />}
        />
        <Route
          path="/services/garden-cleanups/garden-cleanup/bamboo-cleanup"
          element={<BambooCleanup />}
        />
        <Route
          path="/services/garden-cleanups/garden-cleanup/blackberry-removal"
          element={<BlackberryRemoval />}
        />
        <Route path="/e-fleet" element={<EFleet />} />

        {/* Projects Routes */}
        <Route path="/our-projects/3d-design" element={<Design3d />} />
        <Route path="/our-projects/paving-stones" element={<PavingStone />} />
        <Route
          path="/our-projects/retaining-walls"
          element={<RetainingWall />}
        />
        <Route
          path="/our-projects/one-time-cleanups"
          element={<OneTimeCleanups />}
        />
        <Route path="/our-projects/turf-installs" element={<TurfInstalls />} />
        <Route path="/our-projects/plantings" element={<Plantings />} />
        {/* Blog Routes */}
        <Route
          path="/blog/paving-stone-quality-and-value"
          element={<PavingStoneBlog />}
        />
        <Route path="/blog/saving-the-bees" element={<BeesBlog />} />
        <Route path="/blog/chafer-beetle" element={<ChaferBeetleBlog />} />
        <Route
          path="/blog/garden-maintenance-guide"
          element={<SeasonalGuideBlog />}
        />
        <Route
          path="/blog/the-essential-guide-to-lawn-fertilization"
          element={<FertilizerBlog />}
        />
        <Route
          path="/blog/responsible-lawn-watering-in-vancouver"
          element={<WateringLawnBlog />}
        />
        <Route
          path="/blog/creating-a-pollinator-and-native-plant-garden"
          element={<PollinatorBlog />}
        />
        <Route
          path="/blog/the-importance-of-a-fall-leaf-cleanup"
          element={<FallLeafCleanupBlog />}
        />
        <Route
          path="/blog/the-essential-guide-to-blackberry-removal-in-greater-vancouver"
          element={<BlackberryRemovalBlog />}
        />
        <Route
          path="/blog/cutting-back-perennials-in-vancouver-a-gardener's-guide"
          element={<CuttingPerennialsBlog />}
        />
        <Route
          path="/blog/late-fall-gardening-bulbs-to-remove-and-plant-in-november-and-december"
          element={<LateFallGardeningBlog />}
        />
        <Route
          path="/blog/understanding-the-power-of-soils-and-mulch-in-gardening"
          element={<SoilAndMulchBlog />}
        />
         <Route
          path="/blog/snow-and-ice-management-tips-for-north-and-west-vancouver-homeowners"   
          element={<SnowIceManagementBlog />}
        />
         <Route
          path="/blog/replacing-a-timber-wall-with-an-allan-block-wall"
          element={<ReplacingTimberwallBlog />}
        />
        <Route
          path="/blog/expert-walkway-installation-in-greater-vancouver-a-complete-guide"
          element={<ExpertWalkwayBlog />}
        />
         <Route
          path="/blog/spring-lawn-and-garden-tips-for-a-healthy-yard"
          element={<SpringLawnBlog />}
        />
        <Route
          path="/blog/red-thread-causes-fixes-and-spring-prevention-tips"
          element={<RedThreadBlog />}
        />
        <Route
        path="/blog/sustainable-landscaping-a-vision-for-north-and-west-vancouver"
        element={<SustainableLandscapingBlog />}
      />
      <Route
        path="/blog/cedar-timber-raised-garden-beds-a-durable-and-safe-choice"
        element={<CedarTimberBlog />}
        />
      <Route
        path="/blog/creating-your-outdoor-living-space-in-vancouver"
        element={<CreatingYourOutdoorBlog />}
       
      />
      <Route
        path="/blog/aerating-your-lawn-how-to-do-it-right-in-vancouver"
        element={<AeratingYourLawnBlog />}
       
      />
      <Route
        path="/blog/how-to-read-a-fertilizer-label-and-apply-it-to-your-lawn"
        element={<FertilizerLabelBlog />}
      />
      <Route
        path="/blog/spring-garden-cleanup-guide-for-greater-vancouver"
        element={<SpringGardenBlog />}
      />
      <Route
        path="/blog/bur-han-goes-all-electric!-here's-why-it-matters"
        element={<AllElectricBlog />}
      />
     
        {/* Service Area Routes */}
        <Route path="/vancouver-landscaping-services" element={<Vancouver />} />
        
        <Route
          path="/north-vancouver-landscaping-services"
          element={<NorthVancouver />}
        />
        <Route
          path="/west-vancouver-landscaping-services"
          element={<WestVancouver />}
        />
        <Route path="/burnaby-landscaping-services" element={<Burnaby />} />
        <Route path="/richmond-landscaping-services" element={<Richmond />} />
        <Route path="/pittmeadows-landscaping-services" element={<PittMeadows />} />
        <Route
          path="/new-westminster-landscaping-services"
          element={<NewWestminster />}
        />
        <Route path="/coquitlam-landscaping-services" element={<Coquitlam />} />
        <Route
          path="/port-coquitlam-landscaping-services"
          element={<PortCoquitlam />}
        />
        <Route path="/port-moody-landscaping-services" element={<PortMoody />} />
        <Route path="/maple-ridge-landscaping-services" element={<MapleRidge />} />
        <Route path="/delta-landscaping-services" element={<Delta />} />
        <Route path="/surrey-landscaping-services" element={<Surrey />} />
        <Route path="/white-rock-landscaping-services" element={<WhiteRock />} />
        <Route path="/pitt-meadows-landscaping-services" element={<PittMeadows />} />
       
        <Route path="/west-vancouver-lawn-mowing" element={<WestVancouverLawnMowing />} />
        <Route path="/north-vancouver-lawn-mowing" element={<NorthVancouverLawnMowing />} />
        <Route path="/vancouver-lawn-mowing" element={<VancouverLawnMowing />} />

        
        {/* Catch-all route for 404 page */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

