import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import "../styleAllBlogs.css";

import bhYard from "../../../assets/img/bh-backyard-garden-lawn-design-vancouver.jpg";

export default function SpringGarden() {
  const location = useLocation();

  // Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    const linkTag = document.querySelector('link[rel="canonical"]');
    if (linkTag) {
      linkTag.setAttribute("href", canonicalUrl);
    } else {
      const newLinkTag = document.createElement("link");
      newLinkTag.setAttribute("rel", "canonical");
      newLinkTag.setAttribute("href", canonicalUrl);
      document.head.appendChild(newLinkTag);
    }

    const newTitle = "Spring Garden Cleanup Guide for Greater Vancouver Blog";
    document.title = newTitle;

    const newDescription =
      "Spring is the best time to refresh your garden in Vancouver, North Vancouver, West Vancouver, Burnaby, New Westminster, Coquitlam, Port Coquitlam, Pitt Meadows, Maple Ridge, and Surrey. Learn expert landscaping tips for debris removal, soil preparation, pruning, planting, and lawn care.";
    const descriptionMetaTag = document.querySelector(
      'meta[name="description"]'
    );
    if (descriptionMetaTag) {
      descriptionMetaTag.setAttribute("content", newDescription);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "description");
      newMetaTag.setAttribute("content", newDescription);
      document.head.appendChild(newMetaTag);
    }

    const newKeywords = [
      "Spring garden cleanup,Spring yard cleanup,Garden maintenance,Lawn care services,Spring aeration,Spring fertilization,Mulching services,Pruning and trimming,Moss control for lawns,Spring planting guide,Spring garden cleanup Vancouver,Spring garden cleanup North Vancouver,Spring garden cleanup West Vancouver,Spring garden cleanup Burnaby,Spring garden cleanup New Westminster,Spring garden cleanup Coquitlam,Spring garden cleanup Port Coquitlam,Spring garden cleanup Pitt Meadows,Spring garden cleanup Maple Ridge,Spring garden cleanup Surrey,Lawn care services Vancouver,Lawn care services North Vancouver,Lawn care,services West Vancouver,Lawn care services Burnaby,Lawn care services New Westminster,Lawn care services Coquitlam,Lawn care services Port Coquitlam,Lawn care services Pitt Meadows,Lawn care services Maple Ridge,Lawn care services Surrey,Yard cleanup Vancouver,Yard cleanup North Vancouver,Yard cleanup West Vancouver,Yard cleanup Burnaby,Yard cleanup New Westminster,Yard cleanup Coquitlam,Yard cleanup Port Coquitlam,Yard cleanup Pitt Meadows",
    ].join(", ");
    const keywordsMetaTag = document.querySelector('meta[name="keywords"]');
    if (keywordsMetaTag) {
      keywordsMetaTag.setAttribute("content", newKeywords);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "keywords");
      newMetaTag.setAttribute("content", newKeywords);
      document.head.appendChild(newMetaTag);
    }

    const ogTitleTag = document.querySelector('meta[property="og:title"]');
    if (ogTitleTag) {
      ogTitleTag.setAttribute("content", newTitle);
    } else {
      const newOgTitleTag = document.createElement("meta");
      newOgTitleTag.setAttribute("property", "og:title");
      newOgTitleTag.setAttribute("content", newTitle);
      document.head.appendChild(newOgTitleTag);
    }

    const ogDescriptionTag = document.querySelector(
      'meta[property="og:description"]'
    );
    if (ogDescriptionTag) {
      ogDescriptionTag.setAttribute("content", newDescription);
    } else {
      const newOgDescriptionTag = document.createElement("meta");
      newOgDescriptionTag.setAttribute("property", "og:description");
      newOgDescriptionTag.setAttribute("content", newDescription);
      document.head.appendChild(newOgDescriptionTag);
    }
  }, [location.pathname]);

  return (
    <div className="wrapper">
      <Header />
      <div className="beforeSection"></div>
      <div className="sectionBlogpost springGarden">
        <a href="/blog" className="backPosts">
          <i className="bi bi-arrow-left"></i>
          Back
        </a>
        <article>
          <header>
            <h1>
              <div className="nth-line-1" data-aos="fade-up">
                Spring Garden Cleanup
              </div>
              <div className="nth-line-2" data-aos="fade-up">
                Guide for Greater Vancouver
              </div>
            </h1>
            <p className="lead" data-aos="fade-right"></p>
          </header>
          <hr />
          <section data-aos="fade-right">
            <p>
              Spring is the perfect time to refresh your garden after the long,
              wet winter. A thorough cleanup ensures a vibrant and healthy
              landscape throughout the growing season. Here's what should be
              addressed:
            </p>
            
            <p>
              <span>1. Assess and Clean Up Your Garden: </span>
              <br />
              - Remove Debris: Clear away fallen leaves, dead plants, and other
              organic debris that have accumulated over the winter. This helps
              prevent disease and pest infestations.
              <br />
              - Weeding: Early spring is the best time to remove weeds before
              they establish deep roots. Removing them now reduces competition
              for nutrients and water.
              <br />
              - Check for Winter Damage: Inspect plants, trees, and shrubs for
              signs of frost damage or rot. Remove affected plant parts to
              encourage new growth.
              <br />
              <img
              src={bhYard}
              alt="Beautifully maintained garden with vibrant flowers and a lush green lawn in a Vancouver backyard"
              title="Spring garden with colorful blooms and healthy green lawn in Greater Vancouver."
              width={"90% !important"}
            />
              <span>2. Soil Preparation:</span>
              <br />
              - Soil Testing: Determine your soil's pH and nutrient levels to
              identify necessary amendments.
              <br />
              - Amend Soil: Incorporate organic matter, such as compost and
              manure, to enhance soil structure and fertility. This is
              particularly beneficial in Vancouver's often nutrient-depleted
              soils and acidic.
              <br />
              - Aerate Garden Beds: Loosen compacted soil using a garden fork or
              aerator to improve drainage and root growth.
              <br />
              <br />
            </p>          
            <p>
              <span>3. Mulching:</span>
              <br />
              - Apply Mulch: A 2-3 inch layer of mulch helps retain soil
              moisture, regulate temperature, and suppress weeds. Suitable mulch
              materials include shredded leaves and bark chips. A 2-3 inch layer
              of fresh or composting bark mulch can help to retain soil
              moisture, regulate temperature, suppress weeds, and increase soil
              microbiome. <br />- Refresh Existing Mulch: If mulch is already in
              place, fluff it up and add more as needed to maintain proper
              depth.
            </p>
            <p>
              <span>4. Pruning and Trimming:</span>
              <br />
              Pruning in early spring helps shape plants, encourages healthy
              growth, and removes winter-damaged branches. However, it's
              important to prune at the right time for each plant:
              <br />
              <span>
                What to Prune in Early Spring (Before New Growth Emerges):
              </span><br/>
              - Ornamental Grasses: Cut back dead stems to a few inches above
              the ground to make room for fresh growth.
              <br />
              - Perennials: Trim back dead stems and leaves from perennials like
              hostas, daylilies, and sedums. However, if frost is still a
              concern, delay pruning until temperatures stabilize.
              <br />
              - Roses: Remove any dead, damaged, or weak stems. For hybrid tea
              and floribunda roses, prune back to an outward-facing bud to shape
              the plant.
              <br />
              - Deciduous Shrubs (That Bloom in Summer/Fall): Shrubs such as
              hydrangeas (Hydrangea paniculata and Hydrangea arborescens),
              butterfly bush (Buddleia), and potentilla can be pruned in early
              spring before new growth begins. Cut back old stems to encourage
              stronger flowering.
              <br />
              <span>What NOT to Prune in Spring:</span>-{" "}<br/>
              <span>Spring-Flowering Shrubs (Prune After Blooming):</span>
              <br />
              - Forsythia
              <br />
              - Lilac (Syringa)
              <br />
              - Rhododendrons and Azaleas
              <br />
              - Camellias
              <br />
              - Magnolia
              <br />
              These shrubs bloom on old wood, so pruning too early removes
              flower buds and reduces blooms. Wait until after flowering to
              prune.
              <br />
               <span>Evergreens: </span>Most evergreen shrubs and trees
              should only be lightly trimmed in spring. Avoid heavy pruning, as
              it can cause stress.
              <br />
              <span>5. Planting:</span>
              <br />
              - Early Spring Vegetables: Plant cool-season crops such as
              lettuce, spinach, radishes, and peas as soon as the soil is
              workable.
              <br />
              - Flowering Plants: Add hardy perennials and annuals that thrive
              in Vancouver's climate, such as primroses, pansies, and
              hellebores.
              <br />- Divide and Transplant Perennials: If perennials like
              hostas, irises, or daylilies have become crowded, divide and
              replant them in early spring to encourage healthy growth.
            </p>
            <p>
              <span>6. Lawn Care:</span>
              <br />
              - Aeration: If your lawn has compacted soil, aerating in early
              spring allows better water and nutrient penetration.
              <br />
              - Fertilization: Apply a balanced slow-release fertilizer to give
              your lawn a nutrient boost for strong spring growth.
              <br />- Moss Control: Vancouver's damp climate encourages moss
              growth in lawns. Apply a moss control product containing iron
              sulfate to kill moss. Address underlying causes such as shade,
              poor drainage, or soil acidity by pruning overhanging branches,
              aerating the lawn, and applying lime if necessary.
            </p>
            <p>
              A proper spring cleanup prepares your garden for a healthy growing
              season and enhances its overall beauty. By tackling these tasks
              early, you'll ensure a flourishing landscape throughout the spring
              and summer.
              <strong style={{ color: "var(--yellow-color)" }}>
                {" "}
                BUR-HAN Garden & Lawn Care{" "}
              </strong>{" "}
              offers expert lawn care services to ensure your yard gets the
              right nutrients for healthy, vibrant growth.
            </p>
          </section>
        </article>
        <div className="secondBanner">
          <div className="container" data-aos="fade-down">
            <div className="row">
              <div className="col-md-6">
                <h3>Ready to elevate your outdoor experience?</h3>
                <p>
                  Fill out our contact page, and let our expert landscaping team
                  bring your vision to life with a personalized estimate
                  tailored to your unique preferences and needs.
                </p>
              </div>
              <div className="col-md-6 ">
                <div className="call-to-action text-right">
                  <a href="/contact-us" className="get-a-quote">
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
