import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import "../styleAllBlogs.css";

import bhStihlTeam from "../../../assets/img/bur-han-stihl-lawnmower-team.jpg";
import bhTruck from "../../../assets/img/bur-han-stihl-truck-lawnmower.jpg";

export default function AllElectricBlog() {
  const location = useLocation();

  // Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    const linkTag = document.querySelector('link[rel="canonical"]');
    if (linkTag) {
      linkTag.setAttribute("href", canonicalUrl);
    } else {
      const newLinkTag = document.createElement("link");
      newLinkTag.setAttribute("rel", "canonical");
      newLinkTag.setAttribute("href", canonicalUrl);
      document.head.appendChild(newLinkTag);
    }

    const newTitle = "BUR-HAN Goes Electric: STIHL Battery Lawn Equipment Blog";
    document.title = newTitle;

    const newDescription =
      "BUR-HAN Garden & Lawn Care now uses STIHL battery equipment — quieter, cleaner, and more powerful lawn care for Vancouver, Burnaby, and the North Shore.";
    const descriptionMetaTag = document.querySelector(
      'meta[name="description"]'
    );
    if (descriptionMetaTag) {
      descriptionMetaTag.setAttribute("content", newDescription);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "description");
      newMetaTag.setAttribute("content", newDescription);
      document.head.appendChild(newMetaTag);
    }

    const newKeywords = [
      "battery-powered lawn care, electric landscaping equipment, STIHL battery tools, sustainable lawn care Vancouver,electric lawn, mowing services North Vancouver, quiet electric lawn care Burnaby, eco friendly landscaping equipment West Vancouver, professional battery powered landscapers Vancouver, zero emission landscape maintenance BC, all electric lawn care company Metro Vancouver ,STIHL electric lawn tools for commercial use, environmentally friendly gardening service, green landscaping Vancouver ,electric landscape maintenance contractors",
    ].join(", ");
    const keywordsMetaTag = document.querySelector('meta[name="keywords"]');
    if (keywordsMetaTag) {
      keywordsMetaTag.setAttribute("content", newKeywords);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "keywords");
      newMetaTag.setAttribute("content", newKeywords);
      document.head.appendChild(newMetaTag);
    }

    const ogTitleTag = document.querySelector('meta[property="og:title"]');
    if (ogTitleTag) {
      ogTitleTag.setAttribute("content", newTitle);
    } else {
      const newOgTitleTag = document.createElement("meta");
      newOgTitleTag.setAttribute("property", "og:title");
      newOgTitleTag.setAttribute("content", newTitle);
      document.head.appendChild(newOgTitleTag);
    }

    const ogDescriptionTag = document.querySelector(
      'meta[property="og:description"]'
    );
    if (ogDescriptionTag) {
      ogDescriptionTag.setAttribute("content", newDescription);
    } else {
      const newOgDescriptionTag = document.createElement("meta");
      newOgDescriptionTag.setAttribute("property", "og:description");
      newOgDescriptionTag.setAttribute("content", newDescription);
      document.head.appendChild(newOgDescriptionTag);
    }
  }, [location.pathname]);

  return (
    <div className="wrapper">
      <Header />
      <div className="beforeSection"></div>
      <div className="sectionBlogpost allElectric">
        <a href="/blog" className="backPosts">
          <i className="bi bi-arrow-left"></i>
          Back
        </a>
        <article>
          <header>
            <h1>
              <div className="nth-line-1" data-aos="fade-up">
                BUR-HAN Goes All-Electric!
              </div>
              <div className="nth-line-2" data-aos="fade-up">
                Here's Why It Matters:
                <br />
                Quiet, Powerful, and Sustainable Lawn Care with STIHL Battery
                Equipment
              </div>
            </h1>
            <p className="lead" data-aos="fade-right"></p>
          </header>
          <hr />
          <section data-aos="fade-right">
            <p>
              At BUR-HAN, we are happy to announce a big change in time for our
              30th anniversary — we've gone all electric! We've converted a
              total of 75 gas-powered machines to STIHL battery equipment,
              making this the largest deployment of
              <a href="https://www.stihl.ca/en"> STIHL</a> battery equipment in
              British Columbia. This is a major milestone in our commitment to
              sustainable & professional lawn care — but why should it matter to
              you?
            </p>
            <img
              src={bhStihlTeam}
              alt="Ian Alspach, Robert Hannah, and Desmond Early with STIHL electric mower and BUR-HAN truck – sustainable lawn care team, North Vancouver
"
              title="Pictured from left to right: Ian Alspach (STIHL LIMITED Canada), Robert Hannah (BUR-HAN), Desmond Early (Foreshore Equipment). 2025"
              width={"90% !important"}
              text="
Pictured from left to right: Ian Alspach (STIHL LIMITED Canada), Robert Hannah (BUR-HAN), Desmond Early (Foreshore Equipment). 2025"
            />
            <p>
              <span>1. Professional Grade Quality:</span>
              <br />
              For years, we've been searching for commercially viable electric
              equipment that meets our high standards of professional lawn care.
              Thanks to the help of Desmond Early at{" "}
              <a href="https://www.foreshoreequipment.com/">
                Foreshore Equipment
              </a>
              , who helped source our fleet of electric mowers, blowers, and
              trimmers, we've found the perfect solution with STIHL. STIHL is
              dedicated to providing powerful electric tools that meet industry
              standards.
              <br />
              And while it's still the early days of evaluating the new electric
              motor performance, feedback from our operators suggests that these
              machines not only match but will likely exceed their gas-powered
              counterparts in both performance and uptime. This means that with
              BUR-HAN, you'll be getting the best of the best — all the perks of
              electric equipment, without having to sacrifice any power or
              quality.
              <br />
              <span>2. Quiet But Powerful:</span>
              <br />
              What's some of our clients' favorite things about the change? You
              might've guessed it - less noise. We've all been there: walking
              through a peaceful street only to be disrupted by the hammering
              sounds of nearby construction or maintenance. Gas powered blowers
              generate between 65 and 85 decibels (dB), which can exceed safe
              noise exposure limits and contribute significantly to noise
              pollution in the city. Some stronger machines even reach over 100
              dB, comparable to the noise of a motorcycle or even a jackhammer.
              <br />
              You'll be happy to hear that our electric fleet has been rolled
              out through our entire operations - whether we are operating in
              the city, at schools, offices, art galleries, or at your home, our
              battery equipment will keep things quiet. By choosing BUR-HAN, you
              can enjoy a peaceful environment, even as we work in your yard.
            </p>
            <p>
              <span>3. A Greener Vancouver:</span>
              <br />
              Our transition to battery equipment also aligns with{" "}
              <a href="https://vancouversun.com/news/local-news/vancouver-takes-step-towards-banning-gas-powered-leaf-blowers-by-2024">
                {" "}
                Metro Vancouver's plan
              </a>{" "}
              to phase out small gas-engines in order to create a greener city.
              This is after a{" "}
              <a href="https://vancouversun.com/news/local-news/emission-spewing-gas-lawn-equipment-harmful-metro-vancouver">
                report from Metro Vancouver{" "}
              </a>
              found that gas-powered landscaping equipment can emit carbon
              monoxide, nitrogen oxides, fine particulate matter, and volatile
              organic compounds (VOCs), chemicals which can cause harm to humans
              and the environment alike.
              <br />
              By using STIHL battery, we're not only significantly reducing
              emissions, we're protecting our employees and the environment from
              gas exhaust. We want to cultivate a healthier environment for our
              city and residents —that means you!
            </p>
            <p>
              <span>4. A Shared Commitment to the Environment:</span>
              <br />
              We're proud to share STIHL's commitment to climate action, and are
              excited to highlight their recognition of the{" "}
              <a href="https://unfccc.int/process-and-meetings/the-paris-agreement">
                Paris Climate Agreement
              </a>{" "}
              and goal to achieve climate neutrality worldwide.
              <br />
              We are committed to climate protection and want to be part of
              defining how it happens. STIHL recognizes the Paris Climate
              Agreement and acknowledges the target to limit global warming to
              1.5°C. With this in mind, in 2020 we set ourselves the long-term
              goal of operating in a way that is calculable as climate-neutral.{" "}
              <br />
              We remain true to our roots and are dedicated to protecting
              ecosystems, including by consistently addressing climate change.
              We want to continue developing the circular economy aspects of our
              processes and products so that we are conserving resources; we
              also continue to be committed to providing good working conditions
              for everyone who works for STIHL, both directly and indirectly. In
              all our work around the world, we apply the same high standards to
              make it easier for people to work in and with nature - now and
              into the future.”
              <br />
              <img
                src={bhTruck}
                alt="BUR-HAN Garden & Lawn Care truck beside STIHL electric mower – electric lawn care equipment in North Vancouver, BC"
                title="BUR-HAN Landscaping Truck with STIHL Electric Lawn Mower"
                width={"90% !important"}
              />
              <span>
                Looking To The Future
                <br /> Our Ongoing Commitment to Sustainability:
              </span>{" "}
              <br />
              Sustainability has always been a core value at BUR-HAN and we
              recognize the importance of preserving the environment and
              promoting ecological health throughout BC. Previously, we have
              done this through eliminating harmful herbicides and pesticides
              and by implementing GPS tracking in our trucks to monitor and
              reduce unnecessary idling.
              <br />
              We have also adopted sustainable landscaping practices, through
              techniques such as reducing waste, and in our work we aim to
              develop biodiverse spaces that work harmoniously with nature.
              <br />
              With STIHL, we've taken another step in the right direction to
              lower our carbon footprint. We are more excited than ever to be
              part of the future of sustainable landscaping. By choosing
              BUR-HAN, you're not just choosing professional lawn care — you're
              choosing a company that cares about the health of our community
              and planet.
            </p>
          </section>
        </article>
        <div className="secondBanner">
          <div className="container" data-aos="fade-down">
            <div className="row">
              <div className="col-md-6">
                <h3>Ready to elevate your outdoor experience?</h3>
                <p>
                  Fill out our contact page, and let our expert landscaping team
                  bring your vision to life with a personalized estimate
                  tailored to your unique preferences and needs.
                </p>
              </div>
              <div className="col-md-6 ">
                <div className="call-to-action text-right">
                  <a href="/contact-us" className="get-a-quote">
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
