import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import "../styleAllBlogs.css";

import modernPatioLandscape from "../../../assets/img/modern-patio-landscape-west-vancouver.jpg";
import aerialView from "../../../assets/img/aerial-view-garden-before-landscaping.jpg";
import multiLevelGarden from "../../../assets/img/multi-level-garden-landscaping-pathways.jpg";

export default function CreatingYourOutdoorBlog() {
  const location = useLocation();
  
  // Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    const linkTag = document.querySelector('link[rel="canonical"]');
    if (linkTag) {
      linkTag.setAttribute("href", canonicalUrl);
    } else {
      const newLinkTag = document.createElement("link");
      newLinkTag.setAttribute("rel", "canonical");
      newLinkTag.setAttribute("href", canonicalUrl);
      document.head.appendChild(newLinkTag);
    }

    const newTitle = "Outdoor Living Spaces in Vancouver | Design & Landscaping Tips Blog";
    document.title = newTitle;

    const newDescription =
    "Transform your backyard with a functional outdoor living space. Learn how to plan, budget, and design a stunning patio, kitchen, or garden in Vancouver";
    const descriptionMetaTag = document.querySelector('meta[name="description"]');
    if (descriptionMetaTag) {
      descriptionMetaTag.setAttribute("content", newDescription);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "description");
      newMetaTag.setAttribute("content", newDescription);
      document.head.appendChild(newMetaTag);
    }

    const newKeywords = [
      " Outdoor living spaces Vancouver,Outdoor space design,Backyard landscaping Vancouver,Patio design ideas,Outdoor kitchens Vancouver,Hardscaping and softscaping,How to create an outdoor living space in Vancouver,Best outdoor space ideas for small backyards,Budgeting for an outdoor renovation in Vancouver",
    ].join(", ");
    const keywordsMetaTag = document.querySelector('meta[name="keywords"]');
    if (keywordsMetaTag) {
      keywordsMetaTag.setAttribute("content", newKeywords);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "keywords");
      newMetaTag.setAttribute("content", newKeywords);
      document.head.appendChild(newMetaTag);
    }

    const ogTitleTag = document.querySelector('meta[property="og:title"]');
    if (ogTitleTag) {
      ogTitleTag.setAttribute("content", newTitle);
    } else {
      const newOgTitleTag = document.createElement("meta");
      newOgTitleTag.setAttribute("property", "og:title");
      newOgTitleTag.setAttribute("content", newTitle);
      document.head.appendChild(newOgTitleTag);
    }

    const ogDescriptionTag = document.querySelector('meta[property="og:description"]');
    if (ogDescriptionTag) {
      ogDescriptionTag.setAttribute("content", newDescription);
    } else {
      const newOgDescriptionTag = document.createElement("meta");
      newOgDescriptionTag.setAttribute("property", "og:description");
      newOgDescriptionTag.setAttribute("content", newDescription);
      document.head.appendChild(newOgDescriptionTag);
    }
  }, [location.pathname]);

  return (
    <div className="wrapper">
      <Header />
      <div className="beforeSection"></div>
      <div className="sectionBlogpost creatingOutdoor">
        <a href="/blog" className="backPosts">
          <i className="bi bi-arrow-left"></i>
          Back
        </a>
        <article>
          <header>
            <h1>
              <div className="nth-line-1" data-aos="fade-up">
                Creating Your Outdoor
              </div>
              <div className="nth-line-2" data-aos="fade-up">
                Living Space in Vancouver
              </div>
            </h1>
          </header>
          <hr />
          <section data-aos="fade-right">
            <p>
              Vancouver's mild climate gives us the chance to enjoy outdoor
              living well beyond the summer months. With the right setup, your
              backyard can become an extension of your home—whether it's a cozy
              outdoor living room, a dining area for family meals, or even a
              full kitchen for entertaining. The best part? You don't have to go
              far to escape.
              <br />
              If you've been thinking about turning an underused space into
              something functional and inviting, here's where to start.
              <br />
              <img
                src={modernPatioLandscape}
                alt="Beautiful modern patio landscaping with stone pathways, seating area, and garden beds in West Vancouver."
                title="West Vancouver Modern Patio Landscaping with Stone and Greenery"
                className="img-responsive"
              />
              <span>Start with Your Budget:</span> <br />
              Before getting too deep into design ideas, the first thing to
              figure out is how much you're comfortable spending. Outdoor
              projects can range from a few thousand dollars to well over a
              hundred thousand, depending on the size and features you want.
              <br />
              A good first step is to ask your landscaper for a ballpark price
              based on your ideas. This will give you a rough idea of what's
              realistic before investing time in drawings and plans. The clearer
              you are about your budget, the easier it is to create a design
              that works for you—without having to scale things back later.
              <br />
              <span>Plan It Out:</span>
              <br />
              Once you have a budget in mind, the next step is to map out the
              space. Take exact measurements of the area you're working with. A
              simple sketch—even just on graph paper—helps you see how
              furniture, pathways, and structures will fit together before any
              work starts.
              <br />
              If this isn't something you're comfortable with, a professional
              can help create a design that's both practical and visually
              appealing. The goal is to make the most of your space while
              keeping it functional and comfortable.
              <br />
              <br />
              <span>Hardscaping vs. Softscaping:</span>
              <br />
              Once the plan is in place, it's time to bring it to life. Most
              outdoor projects include a mix of hardscaping and softscaping.
              <br />
              - Hardscaping includes patios, decks, retaining walls, pergolas,
              outdoor kitchens, and anything structural.
              <br />
              - Softscaping is all the greenery—trees, shrubs, garden beds, and
              lawns that make the space feel alive.
              <br />
              A good design balances both. Too much hardscaping can make a space
              feel cold, while too much softscaping can feel unstructured. If
              you're working with a landscaper, check their experience in both
              areas. Look at their past work, read reviews, and if possible,
              talk to their previous clients.
              <br />
              <img
                src={aerialView}
                alt="Aerial view of a backyard before landscaping, showing garden beds and planned pathways."
                title="Backyard Garden Before Landscaping - Aerial View"
                className="img-responsive"
              />
              <span>Adjust as You Go:</span>
              <br />
              Even with a solid plan, there's always room to adjust. Some
              elements might need to be added in phases, and some may be tweaked
              to better fit your budget. The key is to prioritize what matters
              most and build out the rest over time.
              <br />
              A great outdoor space isn't just about looks—it's about making
              your yard more enjoyable. Whether that means a quiet spot to
              relax, a space to entertain friends, or a cozy setup to extend
              your outdoor season, the right design makes all the difference.
              <br />
              <br />
              <img
                src={multiLevelGarden}
                alt="Backyard Garden Before Landscaping - Aerial View"
                title="Landscaping Transformation with Multi-Level Pathways and Stone Steps"
                className="img-responsive"
              />
              <span>Getting Started:</span>
              <br />
              The best way to start? Get an idea of costs early on so you know
              what's possible. From there, a little planning goes a long way in
              making sure your outdoor space is exactly what you want.
              <br />
              If you're interested in creating your outdoor living space or need
              help designing and building one,{" "}
              <strong style={{ color: "var(--yellow-color)" }}>
                {" "}
                BUR-HAN Garden and Lawn Care
              </strong>{" "}
              is here to assist. Contact us to discuss your project and get
              expert landscaping support.
            </p>
          </section>
        </article>
      </div>
      <Footer />
    </div>
  );
}
