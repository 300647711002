import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./style.css";

import stihlBatteryPowered from "../../assets/img/stihl-battery-powered-lawn-mower.jpg";
import stihlHedge from "../../assets/img/stihl-battery-powered-hedge-trimmer.jpg";

export default function EFleet() {
  const location = useLocation();

  // Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    const linkTag = document.querySelector('link[rel="canonical"]');
    if (linkTag) {
      linkTag.setAttribute("href", canonicalUrl);
    } else {
      const newLinkTag = document.createElement("link");
      newLinkTag.setAttribute("rel", "canonical");
      newLinkTag.setAttribute("href", canonicalUrl);
      document.head.appendChild(newLinkTag);
    }

    const newTitle =
      "E-Fleet | Sustainable Electric Landscaping Tools at BUR-HAN Garden & Lawn Care";
    document.title = newTitle;

    const newDescription =
      "Discover how BUR-HAN Garden & Lawn Care is revolutionizing landscaping with STIHL's electric tools. Our eco-friendly, battery-powered equipment ensures quiet operation, zero emissions, and top-tier performance for a greener future. Learn why electric landscaping tools are the best choice for your lawn and the environment.";
    const descriptionMetaTag = document.querySelector(
      'meta[name="description"]'
    );
    if (descriptionMetaTag) {
      descriptionMetaTag.setAttribute("content", newDescription);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "description");
      newMetaTag.setAttribute("content", newDescription);
      document.head.appendChild(newMetaTag);
    }

    const newKeywords = [
      "electric landscaping tools, STIHL electric tools, battery-powered lawn care, sustainable landscaping, eco-friendly gardening, quiet lawn equipment, emission-free landscaping, electric mowers, electric  line trimmers, electric blowers, green lawn care, environmentally friendly landscaping, BUR-HAN electric fleet",
    ].join(", ");
    const keywordsMetaTag = document.querySelector('meta[name="keywords"]');
    if (keywordsMetaTag) {
      keywordsMetaTag.setAttribute("content", newKeywords);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "keywords");
      newMetaTag.setAttribute("content", newKeywords);
      document.head.appendChild(newMetaTag);
    }

    const ogTitleTag = document.querySelector('meta[property="og:title"]');
    if (ogTitleTag) {
      ogTitleTag.setAttribute("content", newTitle);
    } else {
      const newOgTitleTag = document.createElement("meta");
      newOgTitleTag.setAttribute("property", "og:title");
      newOgTitleTag.setAttribute("content", newTitle);
      document.head.appendChild(newOgTitleTag);
    }

    const ogDescriptionTag = document.querySelector(
      'meta[property="og:description"]'
    );
    if (ogDescriptionTag) {
      ogDescriptionTag.setAttribute("content", newDescription);
    } else {
      const newOgDescriptionTag = document.createElement("meta");
      newOgDescriptionTag.setAttribute("property", "og:description");
      newOgDescriptionTag.setAttribute("content", newDescription);
      document.head.appendChild(newOgDescriptionTag);
    }
  }, [location.pathname]);

  return (
    <div className="wrapper">
      <Header />
      <div className="teamHeader">
        <h1>Our Electric Fleet</h1>
      </div>
      <div className="serviceItemsMain">
        <h2 data-aos="fade-down" className="px-5">
          "At our company, we utilize high-performance electric landscaping
          tools from STIHL to enhance efficiency and sustainability in our
          work."
        </h2>
        <hr />
        <h2 className="section-header text-center" data-aos="fade-right"></h2>
        <div className="projectsBox mb-5">
          <div className="row">
            <div className="col-4" data-aos="fade-right">
              <div className="box box7 boxelectric">
                <h4> Mowers</h4>
              </div>
            </div>
            <div className="col-4" data-aos="fade-down">
              <div className="box box8 boxelectric">
                <h4>Blowers</h4>
              </div>
            </div>
            <div className="col-4" data-aos="fade-up">
              <div className="box box9 boxelectric">
                <h4>Line Trimmers</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="banner-section">
          <div className="container">
            <div className="row no-gutters">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <h3 className="heading" data-aos="fade-right">
                  "Empowering Progress, Igniting <span> Results!" </span>
                </h3>
              </div>
              <div
                className="col-lg-6 col-md-12 col-sm-12"
                data-aos="fade-left"
              >
                <p className="text">
                  "Revitalize your outdoor space with our expert landscaping
                  maintenance services, ensuring a thriving and picturesque
                  environment year-round."
                </p>
              </div>
            </div>
          </div>
        </div>
        <section className="section-container">
          <div className="image-container px-5" data-aos="fade-right">
            <img
              src={stihlHedge}
              alt="STIHL electric line trimmer shaping a neatly maintained  line."
              title="Precision  line Trimming with STIHL Battery-Powered Tools"
              className="winterSafetyImg"
            />
          </div>
          <div className="text-container px-5" data-aos="fade-up">
            <span className="caption d-block mb-2 font-weight-bold">
              Outstanding Services
            </span>
            <h3 className="headingImageText text-uppercase ">
              Our Electric Fleet{" "}
            </h3>
            <p>
              At BUR-HAN Garden & Lawn Care, we are deeply committed to
              sustainability and environmentally responsible practices. That's
              why we proudly operate an electric fleet powered by STIHL's
              cutting-edge battery technology. Our zero-emission,
              energy-efficient, and whisper-quiet equipment not only reduces our
              carbon footprint but also ensures a cleaner, safer, and healthier
              outdoor environment for our clients and communities. By embracing
              STIHL's sustainability strategy, we align with global climate
              protection goals, minimizing emissions instead of relying on
              carbon offsets. With innovative technology, outstanding service,
              and a vision for a sustainable future, we continue to set the
              standard for eco-friendly lawn and garden care—delivering
              exceptional results while protecting the planet. 🌿⚡🌎
            </p>
          </div>
        </section>
        <section className="section-container">
          <div className="text-container px-5" data-aos="fade-up">
            <h3 className="headingImageText text-uppercase">
              STIHL Sustainability Strategy:{" "}
            </h3>
            <p>
              We are committed to climate protection and want to be part of
              defining how it happens.
              <br />
              STIHL recognizes the Paris Climate Agreement and acknowledges the
              target to limit global warming to 1.5°C. Our guiding principle
              here is: reducing emissions is more important than carbon
              offsetting.
              <br />
              In our climate strategy we have defined short-, medium- and
              long-term measures that determine what climate protection actions
              are taken throughout the company. Our goal: To no longer leave a
              CO₂ footprint as the STIHL Group, and achieve climate neutrality
              worldwide. We remain true to our roots and are dedicated to
              protecting ecosystems, including by consistently addressing
              climate change. In all our work around the world, we apply the
              same high standards to make it easier for people to work in and
              with nature - now and into the future.
            </p>
          </div>

          <div className="image-container px-5" data-aos="fade-left">
            <img
              src={stihlBatteryPowered}
              alt="STIHL battery-powered lawn mower being used on a green lawn"
              title="Eco-Friendly Lawn Mowing with STIHL Battery-Powered Mower"
              className="snowRemovalImg"
            />
          </div>
        </section>

        <div className="secondBanner">
          <div className="container" data-aos="fade-down">
            <div className="row">
              <div className="col-md-6 ">
                <h3>Ready to elevate your outdoor experience?</h3>
                <p>
                  Fill out our Get a Quote page, and let our expert landscaping
                  team bring your vision to life with a personalized estimate
                  tailored to your unique preferences and needs.
                </p>
              </div>
              <div className="col-md-6 ">
                <div className="call-to-action text-right">
                  <a href="/contact-us" className="get-a-quote">
                    Get A Quote
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
