import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import "../styleAllBlogs.css";

import professionalFertilizerBag from "../../../assets/img/professional-fertilizer-bag-23-3-23.jpg.jpg";

export default function FertilizerLabel() {
  const location = useLocation();

  // Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    const linkTag = document.querySelector('link[rel="canonical"]');
    if (linkTag) {
      linkTag.setAttribute("href", canonicalUrl);
    } else {
      const newLinkTag = document.createElement("link");
      newLinkTag.setAttribute("rel", "canonical");
      newLinkTag.setAttribute("href", canonicalUrl);
      document.head.appendChild(newLinkTag);
    }

    const newTitle =
      "How to Read a Fertilizer Label and Apply It to Your Lawn Blog";
    document.title = newTitle;

    const newDescription =
      "Learn how to read a fertilizer label, measure your lawn, and apply fertilizer using a broadcast spreader to keep your lawn healthy and thriving.";
    const descriptionMetaTag = document.querySelector(
      'meta[name="description"]'
    );
    if (descriptionMetaTag) {
      descriptionMetaTag.setAttribute("content", newDescription);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "description");
      newMetaTag.setAttribute("content", newDescription);
      document.head.appendChild(newMetaTag);
    }

    const newKeywords = [
      "How to read a fertilizer label, Lawn fertilizer application, Apply fertilizer with a broadcast spreader ,Lawn fertilization in Vancouver,How much fertilizer to use,Understanding NPK ratios,Lawn care fertilization tips,Measuring your lawn for fertilizer,Best fertilizer for lawns,How to use a broadcast spreader,Nitrogen, phosphorus, potassium in fertilizer,Lawn maintenance in Vancouver, BC,DIY lawn fertilization,Fertilizer spreader setting,Lawn nutrients",
    ].join(", ");
    const keywordsMetaTag = document.querySelector('meta[name="keywords"]');
    if (keywordsMetaTag) {
      keywordsMetaTag.setAttribute("content", newKeywords);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "keywords");
      newMetaTag.setAttribute("content", newKeywords);
      document.head.appendChild(newMetaTag);
    }

    const ogTitleTag = document.querySelector('meta[property="og:title"]');
    if (ogTitleTag) {
      ogTitleTag.setAttribute("content", newTitle);
    } else {
      const newOgTitleTag = document.createElement("meta");
      newOgTitleTag.setAttribute("property", "og:title");
      newOgTitleTag.setAttribute("content", newTitle);
      document.head.appendChild(newOgTitleTag);
    }

    const ogDescriptionTag = document.querySelector(
      'meta[property="og:description"]'
    );
    if (ogDescriptionTag) {
      ogDescriptionTag.setAttribute("content", newDescription);
    } else {
      const newOgDescriptionTag = document.createElement("meta");
      newOgDescriptionTag.setAttribute("property", "og:description");
      newOgDescriptionTag.setAttribute("content", newDescription);
      document.head.appendChild(newOgDescriptionTag);
    }
  }, [location.pathname]);

  return (
    <div className="wrapper">
      <Header />
      <div className="beforeSection"></div>
      <div className="sectionBlogpost fertilizerLabel">
        <a href="/blog" className="backPosts">
          <i className="bi bi-arrow-left"></i>
          Back
        </a>
        <article>
          <header>
            <h1>
              <div className="nth-line-1" data-aos="fade-up">
                How to Read a Fertilizer Label
              </div>
              <div className="nth-line-2" data-aos="fade-up">
                and Apply It to Your Lawn
              </div>
            </h1>
            <p className="lead" data-aos="fade-right"></p>
          </header>
          <hr />
          <section data-aos="fade-right">
            <p>
              Fertilizing your lawn correctly is essential for maintaining
              healthy, vibrant grass. However, understanding how much fertilizer
              to apply and how to do it properly can be confusing. This guide
              will walk you through how to read a fertilizer label, how to
              measure your lawn, and how to apply fertilizer using a broadcast
              spreader to ensure you're feeding your lawn effectively.
            </p>
            <p>
              <span>Understanding a Fertilizer Label:</span>
              <br />
              Every fertilizer bag has a series of three numbers on the front,
              such as 21-7-14. These numbers represent the percentage of three
              essential nutrients:
              <br />
              - Nitrogen (N): Promotes lush, green growth.
              <br />
              - Phosphorus (P): Supports strong root development.
              <br />
              - Potassium (K): Enhances plant health and stress resistance.
              <br />
              For example, in a 50-lb bag of 21-7-14 fertilizer:
              <br />
              21% nitrogen means the bag contains 10.5 lbs of nitrogen (50 lbs x
              0.21).
              <br />
              If you want to apply 1 lb of nitrogen per 1,000 sq ft, you need to
              determine how much total fertilizer to use.
              <br />
              <span>Formula:</span>
              <br />
              Desired Nitrogen RateNitrogen Percentage x 100=Fertilizer Rate per
              1,000 sq ft\frac(Desired\ Nitrogen\ Rate)(Nitrogen\ Percentage)
              \times 100 = \text(Fertilizer Rate per 1,000 sq ft)Nitrogen
              PercentageDesired Nitrogen Rate x 100=Fertilizer Rate per 1,000 sq
              ft For a 21-7-14 fertilizer: 121 x 100=4.76 lbs of fertilizer per
              1,000 sq ft\frac(1)(21) \times 100 = 4.76\ \text(lbs of fertilizer
              per 1,000 sq ft)211 x 100=4.76 lbs of fertilizer per 1,000 sq ft
              This means you need to apply 4.76 lbs of this fertilizer per 1,000
              sq ft of lawn to supply 1 lb of nitrogen.
              <br />
            </p>
            <img
              src={professionalFertilizerBag}
              alt="Professional fertilizer bag with 23-3-23 formula, including micronutrients, balanced for spring application"
              title="23-3-23 Professional Fertilizer with Micronutrients for Spring Lawn Care"
              width={"90% !important"}
            />
            <p>
              <span>How to Measure Your Lawn:</span>
              <br />
              To apply the correct amount of fertilizer, you first need to
              calculate your lawn's size:
              <br />
              1. Measure the Length and Width:
              <br />
              - Multiply length x width to get the total square footage.
              <br />
              - Example: A 40 ft x 25 ft lawn = 1,000 sq ft.
              <br />
              2. For Irregular Lawns:
              <br />
              - Break them into smaller rectangles, measure each, and add the
              areas together.
              <br />
              3. Subtract Hardscapes:
              <br />- If your lawn includes patios, driveways, or garden beds,
              subtract these areas to get the actual grass surface.
            </p>
            <p>
              <span>Applying Fertilizer with a Broadcast Spreader:</span>
              <br />
              Applying fertilizer evenly is crucial to prevent patchy or burnt
              areas. The best method is to set the spreader to a lower setting
              and make two passes over the lawn rather than applying it all at
              once.
              <br />
              <span>Step-by-Step Application:</span>
              <br />
              1. Set the Spreader to a Lower Setting:
              <br />
              - Reduce the opening to apply only half the required amount per
              pass. <br />
              - This prevents running out too soon and ensures even coverage.
              <br />
              2. Apply in Two Directions:
              <br />- First pass: Walk in north-south rows, covering the entire
              area. <br />
              - Second pass: Walk in east-west rows, using the remaining
              fertilizer. <br />
              - This crisscross pattern ensures uniform distribution.
              <br />
              3. Maintain a Steady Walking Pace: <br />
              - Walking too fast results in under-application. <br />
              - Walking too slow may cause excessive application in certain
              spots. <br />
              4. Check for Even Distribution: <br />
              - If some areas look darker or lighter, adjust the spreader
              setting for future applications. <br />
              5. Activate the Fertilizer: <br />
              - Most fertilizers require water to activate. You can wait for
              rainfall or water the lawn according to your area's watering
              schedule and restrictions. <br />
            </p>
            <p>
              <span>Final Tips for Success:</span>
              <br />✅ Test Your Spreader First - Apply a small amount in a test
              area to check the distribution.
              <br />
              ✅ Store Leftover Fertilizer in a Dry Place - Proper storage
              prevents clumping and extends its usability. <br />
              By understanding your fertilizer label, measuring your lawn
              accurately, and applying fertilizer using a controlled, two-pass
              method, you can ensure your lawn receives the right nutrients
              without waste.
              <br />
              If you're looking for professional assistance with fertilizer
              application,{" "}
              <strong style={{ color: "var(--yellow-color)" }}>
                {" "}
                BUR-HAN Garden & Lawn Care{" "}
              </strong>{" "}
              offers expert lawn care services to ensure your yard gets the
              right nutrients for healthy, vibrant growth.
            </p>
          </section>
        </article>
        <div className="secondBanner">
          <div className="container" data-aos="fade-down">
            <div className="row">
              <div className="col-md-6">
                <h3>Ready to elevate your outdoor experience?</h3>
                <p>
                  Fill out our contact page, and let our expert landscaping team
                  bring your vision to life with a personalized estimate
                  tailored to your unique preferences and needs.
                </p>
              </div>
              <div className="col-md-6 ">
                <div className="call-to-action text-right">
                  <a href="/contact-us" className="get-a-quote">
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
