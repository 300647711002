import React, { useState, useEffect } from "react";
import "./style.css"; // Import the CSS for styling

const TextCarousel = ({ texts, interval = 5000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // Set up the interval to change the text
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, interval);

    // Clear the interval on component unmount
    return () => clearInterval(timer);
  }, [texts.length, interval]);

  return (
    <div className="carousel-container">
      <div className="carousel-text" key={currentIndex}>
       "{texts[currentIndex]}"
      </div>
    </div>
  );
};

export default TextCarousel;