import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import "../../Services/styleAllServices.css";

import hardscapeDesignGravelDriveway from "../../../assets/img/green-lawn-with-rocks..png"
import retainingWallWithLighting from "../../../assets/img/lawn-mowing-service-residential-yard.jpeg";
import burhanTeam from "../../../assets/img/burhan-team.png";


export default function WestVancouverLawnMowing() {

  const location = useLocation();

  // Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    const linkTag = document.querySelector('link[rel="canonical"]');
    if (linkTag) {
      linkTag.setAttribute("href", canonicalUrl);
    } else {
      const newLinkTag = document.createElement("link");
      newLinkTag.setAttribute("rel", "canonical");
      newLinkTag.setAttribute("href", canonicalUrl);
      document.head.appendChild(newLinkTag);
    }

    const newTitle ="West Vancouver Lawn Mowing | BUR-HAN Garden & Lawn Care";
    document.title = newTitle;
    
    const newDescription =
    "";
    const descriptionMetaTag = document.querySelector('meta[name="description"]');
    if (descriptionMetaTag) {
      descriptionMetaTag.setAttribute("content", newDescription);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "description");
      newMetaTag.setAttribute("content", newDescription);
      document.head.appendChild(newMetaTag);
    }

    const newKeywords = [
      "",
    ].join(", ");
    const keywordsMetaTag = document.querySelector('meta[name="keywords"]');
    if (keywordsMetaTag) {
      keywordsMetaTag.setAttribute("content", newKeywords);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "keywords");
      newMetaTag.setAttribute("content", newKeywords);
      document.head.appendChild(newMetaTag);
    }

    const ogTitleTag = document.querySelector('meta[property="og:title"]');
    if (ogTitleTag) {
      ogTitleTag.setAttribute("content", newTitle);
    } else {
      const newOgTitleTag = document.createElement("meta");
      newOgTitleTag.setAttribute("property", "og:title");
      newOgTitleTag.setAttribute("content", newTitle);
      document.head.appendChild(newOgTitleTag);
    }

    const ogDescriptionTag = document.querySelector('meta[property="og:description"]');
    if (ogDescriptionTag) {
      ogDescriptionTag.setAttribute("content", newDescription);
    } else {
      const newOgDescriptionTag = document.createElement("meta");
      newOgDescriptionTag.setAttribute("property", "og:description");
      newOgDescriptionTag.setAttribute("content", newDescription);
      document.head.appendChild(newOgDescriptionTag);
    }
  }, [location.pathname]);

  return (
    <div className="wrapper">
      <Header />
      <div className="serviceItemsHeader">
        <h1 data-aos="zoom-in">West Vancouver Lawn Mowing</h1>
      </div>
      <div className="serviceItemsMain">
        <section id="about" className="about"  >
        <h2 data-aos="fade-down" style={{ margin: "2vw",textAlign: "center",fontSize: "2vw",textTransform: "uppercase"}}>
          "Enhancing the beauty and function of your outdoor space with expert
          lawn mowing services."
        </h2>
        <hr />
        <div className="row writtenContent" >
          <div
            className="col-lg-6 order-1 order-lg-2 px-5 "
            data-aos="fade-left"
          >
            <img
              src={burhanTeam}
              alt="BUR-HAN Landscaping Team "
              className="img-fluid bhTeam"
            />
          </div>
          <div
            className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content"
            data-aos="fade-right"
          >
            <div className="corner-left">
              <div className="title" data-aos="zoom-in">
                <h3>BUR-HAN GARDEN & LAWN CARE</h3>
              </div>
              <div
                className="aboutSentence"
                data-aos="fade-right"
                data-duration="2000"
              >
                <p>
                  BUR-HAN Garden & Lawn Care has been providing Residential &
                  Commercial landscaping services for over 30 years. <a href="/our-projects" className="internal-link">View our completed landscaping projects here.</a> It is our
                  goal to provide our clients with the best possible landscaping
                  services in Vancouver. We are passionate about creating
                  outdoor environments that inspire and captivate. With our
                  unwavering commitment to excellence, we go above and beyond to
                  exceed our clients expectations. From the initial consultation
                  to the final touches, our experienced team works closely with
                  you, ensuring that every detail aligns with your values and
                  preferences. With our comprehensive range of services,
                  including landscape design, installation, and maintenance, we
                  have the expertise to handle projects of any size and
                  complexity. Whether you envision a lush garden, show stopping
                  hardscape, or a sustainable landscape design, our dedicated
                  team of professionals are here to bring your dreams to life.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
        {/* -------------- BANNER SECTION -------------*/}
        <div className="banner-section">
          <div className="container">
            <div className="row no-gutters">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <h3 className="heading" data-aos="fade-right">
                  "Empowering Progress, Igniting <span> Results!" </span>
                </h3>
              </div>
              <div
                className="col-lg-6 col-md-12 col-sm-12"
                data-aos="fade-left"
              >
                <p className="text">
                  "Revitalize your outdoor space with our expert landscaping
                  maintenance services, ensuring a thriving and picturesque
                  environment year-round."
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* -------------- about SECTION -------------*/}
        <section className="section-container">
          <div className="image-container px-5" data-aos="fade-right">
            <img
              src={hardscapeDesignGravelDriveway}
              alt="Beautiful home with a gravel driveway and landscaped hedges, showcasing hardscape design"
              title="Hardscape Design Featuring a Gravel Driveway and Landscaped Hedges"
            />
          </div>
          <div className="text-container px-5" data-aos="fade-up">
            <span className="caption d-block mb-2 font-weight-bold">
              Outstanding Services
            </span>
            <h3 className="headingImageText text-uppercase ">
            West Vancouver LAwn Mowing</h3>
            <p>
            Transform your outdoor space with BUR-HAN's exceptional Lawn Mowing services in West Vancouver. Our team is dedicated to delivering precision and care with every cut, ensuring that your lawn remains lush, vibrant, and impeccably maintained throughout the seasons. We continuously refine our methods and techniques to provide you with a service that not only enhances your property’s appearance but also supports the local environment. Our team undergoes regular training to stay current with industry standards and best practices in lawn care. Whether you need routine mowing or a complete lawn makeover, we are here to help you achieve a well-manicured landscape that adds value and beauty to your property.
            </p>
          </div>
        </section>
        <section className="section-container">
          <div class="text-container px-5" data-aos="fade-up">
            <h3 className="headingImageText text-uppercase">
              Why choose BUR-HAN for your
              <br /> Lawn Mowing needs in West Vancouver?
            </h3>
            <ul>
              <li>
                <strong>Customized Lawn Care Plans:</strong> We recognize that every lawn is unique, and our team tailors services to meet your specific needs and preferences.
              </li>
              <li>
                {" "}
                <strong>Expert Precision:</strong> Our skilled lawn care professionals use the latest equipment and techniques to ensure a clean, even cut every time.
              </li>
              <li>
                {" "}
                <strong> Eco-Friendly Practices:</strong> We prioritize sustainable lawn care by utilizing electric, battery-powered equipment that reduces noise and emissions.
              </li>
              <li>
                <strong>Seasonal Expertise: </strong>Our team understands the seasonal requirements of West Vancouver's climate, ensuring your lawn stays healthy and beautiful year-round.
              </li>
              <li>
                <strong>Property Value Enhancement:</strong> A well-maintained lawn significantly enhances curb appeal and property value. Trust us to keep your landscape looking its best.
              </li>
            </ul>
          </div>
          <div className="image-container px-5" data-aos="fade-left">
            <img
              src={retainingWallWithLighting}
              alt="Elegant retaining wall with built-in lighting and landscaped garden at dusk"
              title="Retaining Wall with Built-In Lighting and Dusk Landscaping"
            />{" "}
          </div>
        </section>
        <div className="secondBanner">
          <div className="container" data-aos="fade-down">
            <div className="row">
              <div className="col-md-6 ">
                <h3>Ready to elevate your outdoor experience?</h3>
                <p>
                  Fill out our Get a Quote page, and let our expert landscaping
                  team bring your vision to life with a personalized estimate
                  tailored to your unique preferences and needs.{" "}
                </p>
              </div>
              <div className="col-md-6 ">
                <div className="call-to-action text-right">
                  <a href="/contact-us" className="get-a-quote">
                    Get A Quote
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
