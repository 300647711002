import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import "../styleAllBlogs.css";

import soilSampleLawn from "../../../assets/img/soil-sample-lawn-health.jpg";
import lawnAerationEquipment from "../../../assets/img/lawn-aeration-equipment-burhan.jpg";

export default function AeratingYourLawnBlog() {
  const location = useLocation();

  // Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    const linkTag = document.querySelector('link[rel="canonical"]');
    if (linkTag) {
      linkTag.setAttribute("href", canonicalUrl);
    } else {
      const newLinkTag = document.createElement("link");
      newLinkTag.setAttribute("rel", "canonical");
      newLinkTag.setAttribute("href", canonicalUrl);
      document.head.appendChild(newLinkTag);
    }

    const newTitle =
      " Lawn Aeration in Vancouver & Beyond: When, Why, and How to Do It Right Blog";
    document.title = newTitle;

    const newDescription =
      "Looking for professional lawn aeration in Vancouver, North Vancouver, West Vancouver, Burnaby & beyond? Improve your lawn's health with core aeration today!";
    const descriptionMetaTag = document.querySelector(
      'meta[name="description"]'
    );
    if (descriptionMetaTag) {
      descriptionMetaTag.setAttribute("content", newDescription);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "description");
      newMetaTag.setAttribute("content", newDescription);
      document.head.appendChild(newMetaTag);
    }

    const newKeywords = [
      "Lawn aeration Vancouver ,Lawn aeration North Vancouver,Lawn aeration West Vancouver,Lawn aeration Burnaby,Lawn aeration Coquitlam,Lawn aeration Port Coquitlam,Lawn aeration Maple Ridge,Lawn aeration Pitt Meadows,Lawn aeration services,Core aeration for lawns,Lawn compaction solutions,Benefits of aerating a lawn,Best time to aerate a lawn in Vancouver,How to aerate a lawn,Lawn care tips for Vancouver,Residential lawn aeration,Commercial lawn aeration,Core aerator vs spike aerator,Lawn aeration and overseeding,How to improve lawn drainage",
    ].join(", ");
    const keywordsMetaTag = document.querySelector('meta[name="keywords"]');
    if (keywordsMetaTag) {
      keywordsMetaTag.setAttribute("content", newKeywords);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "keywords");
      newMetaTag.setAttribute("content", newKeywords);
      document.head.appendChild(newMetaTag);
    }

    const ogTitleTag = document.querySelector('meta[property="og:title"]');
    if (ogTitleTag) {
      ogTitleTag.setAttribute("content", newTitle);
    } else {
      const newOgTitleTag = document.createElement("meta");
      newOgTitleTag.setAttribute("property", "og:title");
      newOgTitleTag.setAttribute("content", newTitle);
      document.head.appendChild(newOgTitleTag);
    }

    const ogDescriptionTag = document.querySelector(
      'meta[property="og:description"]'
    );
    if (ogDescriptionTag) {
      ogDescriptionTag.setAttribute("content", newDescription);
    } else {
      const newOgDescriptionTag = document.createElement("meta");
      newOgDescriptionTag.setAttribute("property", "og:description");
      newOgDescriptionTag.setAttribute("content", newDescription);
      document.head.appendChild(newOgDescriptionTag);
    }
  }, [location.pathname]);

  return (
    <div className="wrapper">
      <Header />
      <div className="beforeSection"></div>
      <div className="sectionBlogpost aeration">
        <a href="/blog" className="backPosts">
          <i className="bi bi-arrow-left"></i>
          Back
        </a>
        <article>
          <header>
            <h1>
              <div className="nth-line-1" data-aos="fade-up">
                Aerating Your Lawn:
              </div>
              <div className="nth-line-2" data-aos="fade-up">
                Why, When, and How to Do It Right in Vancouver
              </div>
            </h1>
            <p className="lead" data-aos="fade-right"></p>
          </header>
          <hr />
          <section data-aos="fade-right">
            <p>
              Lawn aeration is an essential part of maintaining a healthy lawn,
              particularly in Vancouver, where high rainfall can lead to
              compacted soil. Compaction prevents water, air, and nutrients from
              reaching grass roots, weakening the turf over time. Aeration helps
              relieve compaction by removing small plugs of soil, allowing the
              lawn to breathe, absorb water more effectively, and develop
              stronger roots.
            </p>
            <p>
              <span>Types of Lawn Aerators:</span>
              <br />
              In the Vancouver area, the most common type of aerator is the
              drum-style aerator, which features a fixed hole pattern. These
              machines, usually walk-behind models, require an operator and
              typically weigh around 350 pounds. Popular brands such as Ryan,
              Bluebird, and Husqvarna manufacture these aerators, and they are
              widely available for rent.
              <br />
              Unlike spike aerators, which merely push holes into the ground
              (potentially increasing compaction around the puncture points),
              core aerators physically remove plugs of soil, creating open
              spaces that allow for soil expansion and deeper root growth.
            </p>
            <img
              src={soilSampleLawn}
              width={"100% !important"}
              alt="Soil sample in hand for lawn health assessment"
              title="Lawn Soil Health Assessment"
            />
            <p>
              <span>Why Aerate Your Lawn?</span>
              <br />
              Aeration is particularly important for:
              <br />
              - High-traffic lawns - Lawns used frequently by pets, children, or
              foot traffic are more prone to soil compaction.
              <br />
              - Lawns with clay soil - Vancouver's high rainfall can compact
              clay-heavy soil, restricting water movement.
              <br />
              - Improving drainage - Aeration reduces surface water buildup and
              prevents puddling.
              <br />- Encouraging deeper root growth - By reducing soil density,
              aeration encourages roots to grow deeper, making the grass more
              resilient.
            </p>
            <p>
              <span>When to Aerate:</span>
              <br />
              The best times to aerate your lawn in Vancouver are:
              <br />
              - Spring (February-May) - Helps turfgrass establish deeper roots
              for the growing season, leading to a healthier, more
              drought-resistant lawn.
              <br />- Fall (September-November) - Reduces compaction before the
              rainy season, allowing water to penetrate rather than pool on the
              surface. This helps prevent lawn damage and turf loss over the
              winter.
            </p>
            <img
              src={lawnAerationEquipment}
              width={"100% !important"}
              alt="UR-HAN Garden & Lawn Care employee with lawn aeration equipment"
              title="Lawn Aeration Services by BUR-HAN"
            />
            <p>
              <span>How to Properly Aerate Your Lawn: </span>
              <br /> 1. Choose the longest direction of the lawn to start
              aerating.
              <br />
              2. Move in straight passes, keeping a consistent spacing between
              rows to ensure even aeration.
              <br />
              3. Monitor core depth - A well-aerated lawn should produce cores
              between 2 to 3 inches deep.
              <br />
              - If cores are less than 1 inch, the soil is highly compacted, and
              additional aeration may be needed in the near future.
              <br />
              4. Allow soil plugs to break down naturally - Do not rake them up,
              as they will decompose and enrich the soil.
              <br />
              5. Adjust aerator weight if necessary - Some machines allow for
              additional water or steel weights to be added for better
              penetration.
            </p>
            <p>
              <span>Final Thoughts:</span>
              <br />
              Regular aeration is a simple but effective way to maintain a lush,
              resilient lawn in Vancouver's rainy climate. By aerating in both
              spring and fall, you'll improve soil structure, enhance root
              growth, and keep your lawn thriving year-round.
              <br />
             
              If you need professional assistance with aeration,{" "}
              <strong style={{ color: "var(--yellow-color)" }}>
                BUR-HAN Garden & Lawn Care
              </strong>{" "}
              provides expert lawn care services to help ensure your yard
              remains healthy and beautiful.
            </p>
          </section>
        </article>
      </div>
      <Footer />
    </div>
  );
}
