import React, { useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./style.css";
import { useLocation } from "react-router-dom";

import gardenPavingDesingnVancouver from "../../assets/img/garden-paving-design-north-vancouver.jpeg";
import chaferBeetleLawnDamage from "../../assets/img/chafer-beetle-lawn-damage-repair-vancouver.jpg";
import backyardBeehiveVancouver from "../../assets/img/backyard-beehive-vancouver-pollination.png";
import familyLawnCareServices from "../../assets/img/family-lawn-care-services-vancouver.jpg";
import lawnCareProfessionalLimeTreatment from "../../assets/img/lawn-care-professional-lime-treatment.jpg";
import pollinatorFriendlyFlowerGarden from "../../assets/img/pollinator-friendly-flower-garden-vancouver.jpg";
import cuttingPerennialsVancouver from "../../assets/img/cutting-perennials-vancouver.jpg";
import properGardenWatering from "../../assets/img/proper-garden-watering-techniques-vancouver.png";
import handPickingBlackberries from "../../assets/img/hand-picking-blackberries-vancouver.jpg";
import yellowDaffodilsSnow from "../../assets/img/yellow-daffodils-snow-vancouver.jpg";
import mulchAndSoil from "../../assets/img/bark-mulch-for-garden.png";
import fallPath from "../../assets/img/fall-path.jpeg";
import snow from "../../assets/img/snow.jpg";
import allanBlock from "../../assets/img/allan-block-stone-retaining-wall-design.jpg";
import finishedPatioPavers from "../../assets/img/finished-patio-pavers-pathway.jpeg";
import lawnMowingService from "../../assets/img/lawn-mowing-service-residential-yard.jpeg";
import lawnDisease from "../../assets/img/lawn-disease-close-up-damaged-grass.jpg";
import lawn from "../../assets/img/urban-planter-with-ornamental-grasses.jpg";
import raisedGarden from "../../assets/img/raised-garden-beds-with-irrigation.jpg";
import modernPatio from "../../assets/img/modern-patio-landscape-west-vancouver.jpg"
import lawnAeration from "../../assets/img/lawn-aeration-equipment-burhan.jpg"
import imgLogo from "../../assets/img/bur-hanBadge.png";
import FertilizerBag from "../../assets/img/professional-fertilizer-bag-23-3-23.jpg.jpg"
import BhYard from "../../assets/img/bh-backyard-garden-lawn-design-vancouver.jpg"
import imgcommingsoon from "../../assets/img/blog-coming-soon.png";
import BHElectric from "../../assets/img/electric-trimmer.jpg"

export default function Blog() {
  const location = useLocation();

  // Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    const linkTag = document.querySelector('link[rel="canonical"]');
    if (linkTag) {
      linkTag.setAttribute("href", canonicalUrl);
    } else {
      const newLinkTag = document.createElement("link");
      newLinkTag.setAttribute("rel", "canonical");
      newLinkTag.setAttribute("href", canonicalUrl);
      document.head.appendChild(newLinkTag);
    }

    const newTitle = "Blog | BUR-HAN Garden & Lawn Care";
    document.title = newTitle;

    const newDescription =
      "Explore landscaping tips, lawn care advice, and gardening insights tailored for Vancouver and North Vancouver. Learn from BUR-HAN Garden & Lawn Care experts.";
    const descriptionMetaTag = document.querySelector(
      'meta[name="description"]'
    );
    if (descriptionMetaTag) {
      descriptionMetaTag.setAttribute("content", newDescription);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "description");
      newMetaTag.setAttribute("content", newDescription);
      document.head.appendChild(newMetaTag);
    }

    const newKeywords = [
      "landscaping tips Vancouver,lawn care tips North Vancouver, organic gardening BC, outdoor living ideas Vancouver, garden maintenance tips, seasonal lawn care tips, sustainable landscaping ideas, pest control tips Vancouver, fertilizer guide for lawns, hardscaping ideas North Vancouver, natural fertilizers for healthy soil, bee-friendly gardens Vancouver, chafer beetle control Vancouver, spring garden preparation BC, fall leaf cleanup tips, water-efficient landscaping solutions, modern garden paving ideas, family lawn care services, pollinator-friendly garden designs, blackberry removal guide Vancouver,Learn about sustainable landscaping ideas to create a bee-friendly garden in Vancouver.",
    ].join(", ");
    const keywordsMetaTag = document.querySelector('meta[name="keywords"]');
    if (keywordsMetaTag) {
      keywordsMetaTag.setAttribute("content", newKeywords);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "keywords");
      newMetaTag.setAttribute("content", newKeywords);
      document.head.appendChild(newMetaTag);
    }

    const ogTitleTag = document.querySelector('meta[property="og:title"]');
    if (ogTitleTag) {
      ogTitleTag.setAttribute("content", newTitle);
    } else {
      const newOgTitleTag = document.createElement("meta");
      newOgTitleTag.setAttribute("property", "og:title");
      newOgTitleTag.setAttribute("content", newTitle);
      document.head.appendChild(newOgTitleTag);
    }

    const ogDescriptionTag = document.querySelector(
      'meta[property="og:description"]'
    );
    if (ogDescriptionTag) {
      ogDescriptionTag.setAttribute("content", newDescription);
    } else {
      const newOgDescriptionTag = document.createElement("meta");
      newOgDescriptionTag.setAttribute("property", "og:description");
      newOgDescriptionTag.setAttribute("content", newDescription);
      document.head.appendChild(newOgDescriptionTag);
    }
  }, [location.pathname]);

  return (
    <div className="wrapper">
      <Header />
      <div className="blogHeader">
        <h1 data-aos="zoom-in"> Blog</h1>
      </div>
      <div className="blogMain" data-aos="fade-up">
        <h2 data-aos="fade-down">
          Welcome to BUR-HAN's blog for expert tips on landscaping, lawn care,
          and gardening.
        </h2>

        <hr />

        <div className="container">
          <section className="featured-posts">
            <div className="cardBlog-columns listfeaturedtag">
            <div className="cardBlog" data-aos="fade-up">
                <a href="/blog/bur-han-goes-all-electric!-here's-why-it-matters">
                  <div className="row">
                    <div className="col-5 wrapthumbnail">
                      <div
                        className="thumbnail"
                        style={{ backgroundImage: `url(${BHElectric})` }}
                      ></div>
                    </div>
                    <div className="col-7">
                      <div className="cardBlog-block">
                        <h2 className="cardBlog-title mll">
                        BUR-HAN Goes All-Electric! Here's Why It Matters                     </h2>
                        <div className="metafooter">
                          <div className="tagColor c1">landscaping</div>
                          <div className="wrapfooter">
                            <span className="meta-footer-thumb">
                              <img
                                className="author-thumb"
                                src={imgLogo}
                                alt="BUR-HAN logo"
                              />
                            </span>
                            <span className="author-meta">
                              <span className="post-name">
                                By The Bur-Han Team
                              </span>
                              <br />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            <div className="cardBlog" data-aos="fade-up">
                <a href="/blog/how-to-read-a-fertilizer-label-and-apply-it-to-your-lawn">
                  <div className="row">
                    <div className="col-5 wrapthumbnail">
                      <div
                        className="thumbnail"
                        style={{
                          backgroundImage: `url(${FertilizerBag})`,
                        }}
                      ></div>
                    </div>
                    <div className="col-7">
                      <div className="cardBlog-block">
                        <h2 className="cardBlog-title mll">
                      How to Read a Fertilizer Label and Apply it to Your Lawn
                        </h2>
                        <div className="metafooter">
                          <div className="tagColor c1">landscaping</div>
                          <div className="wrapfooter">
                            <span className="meta-footer-thumb">
                              <img
                                className="author-thumb"
                                src={imgLogo}
                                alt="BUR-HAN spring"
                              />
                            </span>
                            <span className="author-meta">
                              <span className="post-name">
                                By The Bur-Han Team
                              </span>
                              <br />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            
              <div className="cardBlog" data-aos="fade-up">
                <a href="/blog/cedar-timber-raised-garden-beds-a-durable-and-safe-choice">
                  <div className="row">
                    <div className="col-5 wrapthumbnail">
                      <div
                        className="thumbnail"
                        style={{
                          backgroundImage: `url(${raisedGarden})`,
                        }}
                      ></div>
                    </div>
                    <div className="col-7">
                      <div className="cardBlog-block">
                        <h2 className="cardBlog-title mll">
                          Cedar Timber Raised Garden Beds: A Durable and Safe
                          Choice
                        </h2>
                        <div className="metafooter">
                          <div className="tagColor c2">hardscaping</div>
                          <div className="wrapfooter">
                            <span className="meta-footer-thumb">
                              <img
                                className="author-thumb"
                                src={imgLogo}
                                alt="BUR-HAN spring"
                              />
                            </span>
                            <span className="author-meta">
                              <span className="post-name">
                                By The Bur-Han Team
                              </span>
                              <br />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>

              <div className="cardBlog" data-aos="fade-up">
                <a href="blog/spring-lawn-and-garden-tips-for-a-healthy-yard">
                  <div className="row">
                    <div className="col-5 wrapthumbnail">
                      <div
                        className="thumbnail"
                        style={{
                          backgroundImage: `url(${lawnMowingService})`,
                        }}
                      ></div>
                    </div>
                    <div className="col-7">
                      <div className="cardBlog-block">
                        <h2 className="cardBlog-title mll">
                          Spring Lawn and Garden Tips for a Healthy Yard
                        </h2>
                        <div className="metafooter">
                          <div className="tagColor c1">landscaping</div>
                          <div className="wrapfooter">
                            <span className="meta-footer-thumb">
                              <img
                                className="author-thumb"
                                src={imgLogo}
                                alt="BUR-HAN spring"
                              />
                            </span>
                            <span className="author-meta">
                              <span className="post-name">
                                By The Bur-Han Team
                              </span>
                              <br />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>

              <div className="cardBlog" data-aos="fade-up">
                <a href="/blog/replacing-a-timber-wall-with-an-allan-block-wall">
                  <div className="row">
                    <div className="col-5 wrapthumbnail">
                      <div
                        className="thumbnail"
                        style={{
                          backgroundImage: `url(${allanBlock})`,
                        }}
                      ></div>
                    </div>
                    <div className="col-7">
                      <div className="cardBlog-block">
                        <h2 className="cardBlog-title mll">
                          Replacing a Timber Wall with an Allan Block Wall: A
                          Smart Investment for Longevity
                        </h2>
                        <div className="metafooter">
                          <div className="tagColor c2">hardscaping</div>
                          <div className="wrapfooter">
                            <span className="meta-footer-thumb">
                              <img
                                className="author-thumb"
                                src={imgLogo}
                                alt="BUR-HAN spring"
                              />
                            </span>
                            <span className="author-meta">
                              <span className="post-name">
                                By The Bur-Han Team
                              </span>
                              <br />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>

              <div className="cardBlog" data-aos="fade-up">
                <a href="/blog/understanding-the-power-of-soils-and-mulch-in-gardening">
                  <div className="row">
                    <div className="col-5 wrapthumbnail">
                      <div
                        className="thumbnail"
                        style={{ backgroundImage: `url(${mulchAndSoil})` }}
                      ></div>
                    </div>
                    <div className="col-7">
                      <div className="cardBlog-block">
                        <h2 className="cardBlog-title mll">
                          Understanding the Power of Soils and Mulch in
                          Gardening
                        </h2>
                        <div className="metafooter">
                          <div className="tagColor c1">landscaping</div>
                          <div className="wrapfooter">
                            <span className="meta-footer-thumb">
                              <img
                                className="author-thumb"
                                src={imgLogo}
                                alt="BUR-HAN logo"
                              />
                            </span>
                            <span className="author-meta">
                              <span className="post-name">
                                By The Bur-Han Team
                              </span>
                              <br />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
             
              <div className="cardBlog" data-aos="fade-up">
                <a href="/blog/spring-garden-cleanup-guide-for-greater-vancouver">
                  <div className="row">
                    <div className="col-5 wrapthumbnail">
                      <div
                        className="thumbnail"
                        style={{
                          backgroundImage: `url(${BhYard})`,
                        }}
                      ></div>
                    </div>
                    <div className="col-7">
                      <div className="cardBlog-block">
                        <h2 className="cardBlog-title mll">
                        Spring Garden Cleanup Guide for Greater Vancouver
                        </h2>
                        <div className="metafooter">
                          <div className="tagColor c1">landscaping</div>
                          <div className="wrapfooter">
                            <span className="meta-footer-thumb">
                              <img
                                className="author-thumb"
                                src={imgLogo}
                                alt="BUR-HAN spring"
                              />
                            </span>
                            <span className="author-meta">
                              <span className="post-name">
                                By The Bur-Han Team
                              </span>
                              <br />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            <div className="cardBlog" data-aos="fade-up">
                <a href="/blog/aerating-your-lawn-how-to-do-it-right-in-vancouver">
                  <div className="row">
                    <div className="col-5 wrapthumbnail">
                      <div
                        className="thumbnail"
                        style={{
                          backgroundImage: `url(${lawnAeration})`,
                        }}
                      ></div>
                    </div>
                    <div className="col-7">
                      <div className="cardBlog-block">
                        <h2 className="cardBlog-title mll">
                        Aerating Your Lawn: Why, When, and How to Do It Right in Vancouver
                        </h2>
                        <div className="metafooter">
                          <div className="tagColor c1">landscaping</div>
                          <div className="wrapfooter">
                            <span className="meta-footer-thumb">
                              <img
                                className="author-thumb"
                                src={imgLogo}
                                alt="BUR-HAN spring"
                              />
                            </span>
                            <span className="author-meta">
                              <span className="post-name">
                                By The Bur-Han Team
                              </span>
                              <br />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="cardBlog" data-aos="fade-up">
                <a href="/blog/creating-your-outdoor-living-space-in-vancouver">
                  <div className="row">
                    <div className="col-5 wrapthumbnail">
                      <div
                        className="thumbnail"
                        style={{
                          backgroundImage: `url(${modernPatio})`,
                        }}
                      ></div>
                    </div>
                    <div className="col-7">
                      <div className="cardBlog-block">
                        <h2 className="cardBlog-title mll">
                          Creating Your Outdoor Living Space in Vancouver
                        </h2>
                        <div className="metafooter">
                          <div className="tagColor c2">hardscaping</div>
                          <div className="wrapfooter">
                            <span className="meta-footer-thumb">
                              <img
                                className="author-thumb"
                                src={imgLogo}
                                alt="BUR-HAN spring"
                              />
                            </span>
                            <span className="author-meta">
                              <span className="post-name">
                                By The Bur-Han Team
                              </span>
                              <br />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>

              <div className="cardBlog" data-aos="fade-up">
                <a href="/blog/sustainable-landscaping-a-vision-for-north-and-west-vancouver">
                  <div className="row">
                    <div className="col-5 wrapthumbnail">
                      <div
                        className="thumbnail"
                        style={{
                          backgroundImage: `url(${lawn})`,
                        }}
                      ></div>
                    </div>
                    <div className="col-7">
                      <div className="cardBlog-block">
                        <h2 className="cardBlog-title mll">
                          Sustainable Landscaping: A Vision for North and West
                          Vancouver
                        </h2>
                        <div className="metafooter">
                          <div className="tagColor c1">landscaping</div>
                          <div className="wrapfooter">
                            <span className="meta-footer-thumb">
                              <img
                                className="author-thumb"
                                src={imgLogo}
                                alt="BUR-HAN spring"
                              />
                            </span>
                            <span className="author-meta">
                              <span className="post-name">
                                By The Bur-Han Team
                              </span>
                              <br />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>

              <div className="cardBlog" data-aos="fade-up">
                <a href="/blog/red-thread-causes-fixes-and-spring-prevention-tips">
                  <div className="row">
                    <div className="col-5 wrapthumbnail">
                      <div
                        className="thumbnail"
                        style={{
                          backgroundImage: `url(${lawnDisease})`,
                        }}
                      ></div>
                    </div>
                    <div className="col-7">
                      <div className="cardBlog-block">
                        <h2 className="cardBlog-title mll">
                          Red Thread: Causes, Fixes, and Spring Prevention Tips
                        </h2>
                        <div className="metafooter">
                          <div className="tagColor c1">landscaping</div>
                          <div className="wrapfooter">
                            <span className="meta-footer-thumb">
                              <img
                                className="author-thumb"
                                src={imgLogo}
                                alt="BUR-HAN spring"
                              />
                            </span>
                            <span className="author-meta">
                              <span className="post-name">
                                By The Bur-Han Team
                              </span>
                              <br />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>

              <div className="cardBlog" data-aos="fade-up">
                <a href="/blog/expert-walkway-installation-in-greater-vancouver-a-complete-guide">
                  <div className="row">
                    <div className="col-5 wrapthumbnail">
                      <div
                        className="thumbnail"
                        style={{
                          backgroundImage: `url(${finishedPatioPavers})`,
                        }}
                      ></div>
                    </div>
                    <div className="col-7">
                      <div className="cardBlog-block">
                        <h2 className="cardBlog-title mll">
                          Expert Walkway Installation in Greater Vancouver - A
                          Complete Guide
                        </h2>
                        <div className="metafooter">
                          <div className="tagColor c2">hardscaping</div>
                          <div className="wrapfooter">
                            <span className="meta-footer-thumb">
                              <img
                                className="author-thumb"
                                src={imgLogo}
                                alt="BUR-HAN spring"
                              />
                            </span>
                            <span className="author-meta">
                              <span className="post-name">
                                By The Bur-Han Team
                              </span>
                              <br />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            
              

            </div>
          </section>
          <section className="recentBlog-posts">
            <div className="sectionBlog-title">
              <h2>
                <span data-aos="fade-up">More Blog Posts</span>
              </h2>
            </div>
            <div className="cardBlog-columns listrecent">
              <div className="cardBlog" data-aos="fade-left">
                <a href="/blog/snow-and-ice-management-tips-for-north-and-west-vancouver-homeowners">
                  <img
                    className="img-fluid"
                    src={snow}
                    alt="Hand-picking ripe blackberries in a Vancouver garden"
                    title="Blackberry Harvesting Tips for Vancouver Gardens"
                  />
                  <div className="cardBlog-block">
                    <h2 className="cardBlog-title">
                      Snow and Ice Management
                      <br />
                      <br />
                      <br />
                    </h2>
                    <div className="metafooter">
                      <div className="tagColor1 c1">landscaping</div>
                      <div className="wrapfooter">
                        <span className="meta-footer-thumb">
                          <img
                            className="author-thumb"
                            src={imgLogo}
                            alt="BUR-HAN"
                          />
                        </span>
                        <span className="author-meta">
                          <span className="post-name">By The Bur-Han Team</span>
                          <br />
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="cardBlog" data-aos="fade-left">
                <a href="/blog/the-essential-guide-to-blackberry-removal-in-greater-vancouver">
                  <img
                    className="img-fluid"
                    src={handPickingBlackberries}
                    alt="Hand-picking ripe blackberries in a Vancouver garden"
                    title="Blackberry Harvesting Tips for Vancouver Gardens"
                  />
                  <div className="cardBlog-block">
                    <h2 className="cardBlog-title">
                      The Essential Guide to Blackberry Removal in Greater
                      Vancouver{" "}
                    </h2>
                    <div className="metafooter">
                      <div className="tagColor1 c1">landscaping</div>
                      <div className="wrapfooter">
                        <span className="meta-footer-thumb">
                          <img
                            className="author-thumb"
                            src={imgLogo}
                            alt="BUR-HAN"
                          />
                        </span>
                        <span className="author-meta">
                          <span className="post-name">By The Bur-Han Team</span>
                          <br />
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>

              <div className="cardBlog" data-aos="fade-left">
                <a href="/blog/creating-a-pollinator-and-native-plant-garden">
                  <img
                    className="img-fluid"
                    src={pollinatorFriendlyFlowerGarden}
                    alt="Vibrant pollinator-friendly flower garden with pink, blue, and yellow blooms in Vancouver."
                    title="Pollinator-Friendly Flower Gardens in Vancouver "
                  />
                  <div className="cardBlog-block">
                    <h2 className="cardBlog-title">
                      Creating a Pollinator and Native Plant Garden
                    </h2>
                    <div className="metafooter">
                      <div className="tagColor1 c1">landscaping</div>
                      <div className="wrapfooter">
                        <span className="meta-footer-thumb">
                          <img
                            className="author-thumb"
                            src={imgLogo}
                            alt="BUR-HAN"
                          />
                        </span>
                        <span className="author-meta">
                          <span className="post-name">By The Bur-Han Team</span>
                          <br />
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="cardBlog" data-aos="fade-left">
                <a href="/blog/responsible-lawn-watering-in-vancouver">
                  <img
                    className="img-fluid"
                    src={properGardenWatering}
                    alt="Watering a vegetable garden with precision for healthy growth in Vancouver"
                    title="Proper Garden Watering Techniques for Healthy Plants in Vancouver "
                  />
                  <div className="cardBlog-block">
                    <h2 className="cardBlog-title">
                      Responsible Lawn Watering in Vancouver
                      <br />
                    </h2>
                    <div className="metafooter">
                      <div className="tagColor1 c1">landscaping</div>
                      <div className="wrapfooter">
                        <span className="meta-footer-thumb">
                          <img
                            className="author-thumb"
                            src={imgLogo}
                            alt="BUR-HAN"
                          />
                        </span>
                        <span className="author-meta">
                          <span className="post-name">By The Bur-Han Team</span>
                          <br />
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="cardBlog" data-aos="fade-left">
                <a href="/blog/cutting-back-perennials-in-vancouver-a-gardener's-guide">
                  <img
                    className="img-fluid"
                    src={cuttingPerennialsVancouver}
                    alt="Hand-picking ripe blackberries in a Vancouver garden"
                    title="Blackberry Harvesting Tips for Vancouver Gardens"
                  />
                  <div className="cardBlog-block">
                    <h2 className="cardBlog-title">
                      Cutting Back Perennials in Vancouver: A Gardener's Guide
                    </h2>
                    <div className="metafooter">
                      <div className="tagColor1 c1">landscaping</div>
                      <div className="wrapfooter">
                        <span className="meta-footer-thumb">
                          <img
                            className="author-thumb"
                            src={imgLogo}
                            alt="BUR-HAN"
                          />
                        </span>
                        <span className="author-meta">
                          <span className="post-name">By The Bur-Han Team</span>
                          <br />
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="cardBlog" data-aos="fade-left">
                <a href="/blog/the-importance-of-a-fall-leaf-cleanup">
                  <img
                    className="img-fluid"
                    src={fallPath}
                    alt=" Dog walking on a fall trail with colorful foliage and fallen leaves."
                    title="Scenic Fall Trail with Dog Walking "
                  />
                  <div className="cardBlog-block">
                    <h2 className="cardBlog-title">
                      The Importance Of A Fall Leaf Cleanup
                      <br />
                      <br />
                    </h2>
                    <div className="metafooter">
                      <div className="tagColor1 c1">landscaping</div>
                      <div className="wrapfooter">
                        <span className="meta-footer-thumb">
                          <img
                            className="author-thumb"
                            src={imgLogo}
                            alt="BUR-HAN"
                          />
                        </span>
                        <span className="author-meta">
                          <span className="post-name">By The Bur-Han Team</span>
                          <br />
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="cardBlog" data-aos="fade-left">
                <a href="/blog/garden-maintenance-guide">
                  <img
                    className="img-fluid"
                    src={familyLawnCareServices}
                    alt="Family enjoying a well-maintained backyard with professional lawn care services in Vancouver."
                    title="Family-Friendly Lawn Care Services in Vancouver by BUR-HAN Garden & Lawn Care"
                  />
                  <div className="cardBlog-block">
                    <h2 className="cardBlog-title">
                      Garden Maintenance Guide - SPRING
                    </h2>
                    <div className="metafooter">
                      <div className="tagColor1 c1">landscaping</div>
                      <div className="wrapfooter">
                        <span className="meta-footer-thumb">
                          <img
                            className="author-thumb"
                            src={imgLogo}
                            alt="BUR-HAN"
                          />
                        </span>
                        <span className="author-meta">
                          <span className="post-name">By The Bur-Han Team</span>
                          <br />
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="cardBlog" data-aos="fade-left">
                <a href="/blog/chafer-beetle">
                  <img
                    className="img-fluid"
                    src={chaferBeetleLawnDamage}
                    alt="Chafer beetle damage on a lawn being inspected and repaired in Vancouver by BUR-HAN Garden & Lawn Care."
                    title="Expert Chafer Beetle Lawn Damage Repair Services in Vancouver"
                  />
                  <div className="cardBlog-block">
                    <h2 className="cardBlog-title">
                      The Chafer Beetle <br />
                      <br />
                    </h2>
                    <div className="metafooter">
                      <div className="tagColor1 c1">landscaping</div>
                      <div className="wrapfooter">
                        <span className="meta-footer-thumb">
                          <img
                            className="author-thumb"
                            src={imgLogo}
                            alt="BUR-HAN"
                          />
                        </span>
                        <span className="author-meta">
                          <span className="post-name">By The Bur-Han Team</span>
                          <br />
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="cardBlog" data-aos="fade-left">
                <a href="/blog/late-fall-gardening-bulbs-to-remove-and-plant-in-november-and-december">
                  <img
                    className="img-fluid"
                    src={yellowDaffodilsSnow}
                    alt="Hand-picking ripe blackberries in a Vancouver garden"
                    title="Blackberry Harvesting Tips for Vancouver Gardens"
                  />
                  <div className="cardBlog-block">
                    <h2 className="cardBlog-title">
                      Late Fall Gardening: Bulbs to Remove and Plant in November
                      and December
                    </h2>
                    <div className="metafooter">
                      <div className="tagColor1 c1">landscaping</div>
                      <div className="wrapfooter">
                        <span className="meta-footer-thumb">
                          <img
                            className="author-thumb"
                            src={imgLogo}
                            alt="BUR-HAN"
                          />
                        </span>
                        <span className="author-meta">
                          <span className="post-name">By The Bur-Han Team</span>
                          <br />
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="cardBlog" data-aos="fade-left">
                <a href="/blog/the-essential-guide-to-lawn-fertilization">
                  <img
                    className="img-fluid"
                    src={lawnCareProfessionalLimeTreatment}
                    alt="A lawn care professional applying lime treatment on a vibrant green lawn in Vancouver."
                    title="Professional Lawn Lime Treatment Services in Vancouver "
                  />
                  <div className="cardBlog-block">
                    <h2 className="cardBlog-title">
                      The Essential Guide to Lawn Fertilization
                      <br />
                      <br />
                    </h2>
                    <div className="metafooter">
                      <div className="tagColor1 c1">landscaping</div>
                      <div className="wrapfooter">
                        <span className="meta-footer-thumb">
                          <img
                            className="author-thumb"
                            src={imgLogo}
                            alt="BUR-HAN"
                          />
                        </span>
                        <span className="author-meta">
                          <span className="post-name">By The Bur-Han Team</span>
                          <br />
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="cardBlog" data-aos="fade-left">
                <a href="/blog/paving-stone-quality-and-value">
                  <img
                    className="img-fluid"
                    src={gardenPavingDesingnVancouver}
                    alt="Modern garden with elegant paving and lush greenery designed by BUR-HAN Garden & Lawn Care in North Vancouver."
                    title="Modern Garden Paving and Landscape Design Services in North Vancouver"
                  />
                  <div className="cardBlog-block">
                    <h2 className="cardBlog-title">
                      Paving Stone - Quality and Value
                    </h2>
                    <div className="metafooter">
                      <div className="tagColor1 c2">hardscaping</div>
                      <div className="wrapfooter">
                        <span className="meta-footer-thumb">
                          <img
                            className="author-thumb"
                            src={imgLogo}
                            alt="BUR-HAN"
                          />
                        </span>
                        <span className="author-meta">
                          <span className="post-name">By The Bur-Han Team</span>
                          <br />
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="cardBlog" data-aos="fade-left">
                <a href="/blog/saving-the-bees">
                  <img
                    className="img-fluid"
                    src={backyardBeehiveVancouver}
                    alt="A backyard beehive for sustainable pollination and biodiversity in Vancouver, BC."
                    title="Sustainable Backyard Beekeeping for Pollination in Vancouver"
                  />
                  <div className="cardBlog-block">
                    <h2 className="cardBlog-title">
                      Saving the Bees
                      <br />
                      <br />
                    </h2>
                    <div className="metafooter">
                      <div className="tagColor1 c1">landscaping</div>
                      <div className="wrapfooter">
                        <span className="meta-footer-thumb">
                          <img
                            className="author-thumb"
                            src={imgLogo}
                            alt="BUR-HAN"
                          />
                        </span>
                        <span className="author-meta">
                          <span className="post-name">By The Bur-Han Team</span>
                          <br />
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </div>
  );
}
