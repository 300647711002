import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import "../styleAllServices.css";
import TextCarousel from "../../../components/TextCarousel"; // Import the TextCarousel component

import snowCoveredStairsWalkway from "../../../assets/img/snow-covered-stairs-walkway.jpeg";
import snowplowClearingParkingLot from "../../../assets/img/snowplow-clearing-parking-lot.jpeg";

export default function SnowManagement() {
  
  const location = useLocation();

  const carouselTexts = [
    "I just wanted to acknowledge that service has been great as per your contract with our strata! - Linda in Vancouver",
    "We saw a BUR-HAN vehicle in Kerrisdale today and felt a little *pride of ownership* as the company is one of *our trades* now! Take care and have a great day! - Linda in Vancouver",
   "I'm grateful you were able to get your crew here so quickly! You guys rock! - Carolanne in North Vancouver",  
   "Please send kudos to the crew servicing our property. We appreciate all the care and attention they have given us! Cheers! - Iris in North Vancouver",

  ];

  // Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    const linkTag = document.querySelector('link[rel="canonical"]');
    if (linkTag) {
      linkTag.setAttribute("href", canonicalUrl);
    } else {
      const newLinkTag = document.createElement("link");
      newLinkTag.setAttribute("rel", "canonical");
      newLinkTag.setAttribute("href", canonicalUrl);
      document.head.appendChild(newLinkTag);
    }

    const newTitle = "Snow Management | BUR-HAN Garden & Lawn Care";
    document.title = newTitle;

    const newDescription =
    "Enhance the beauty and function of your outdoor space with expert snow management services from BUR-HAN. Our comprehensive solutions ensure safe and accessible sites during winter months, backed by a commitment to reliability, live monitoring, and timely billing.";
    const descriptionMetaTag = document.querySelector('meta[name="description"]');
    if (descriptionMetaTag) {
      descriptionMetaTag.setAttribute("content", newDescription);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "description");
      newMetaTag.setAttribute("content", newDescription);
      document.head.appendChild(newMetaTag);
    }

    const newKeywords = [
      "snow management, snow plowing, snow shovelling, snow blowing, salting, pre-treatment, post-treatment, 24/7 response, seasonal contracts, snow monitoring, insured, bonded, warehousing, residential, commercial, Vancouver, Burnaby, North Vancouver, West Vancouver, services, snow, winter",
    ].join(", ");
    const keywordsMetaTag = document.querySelector('meta[name="keywords"]');
    if (keywordsMetaTag) {
      keywordsMetaTag.setAttribute("content", newKeywords);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "keywords");
      newMetaTag.setAttribute("content", newKeywords);
      document.head.appendChild(newMetaTag);
    }

    const ogTitleTag = document.querySelector('meta[property="og:title"]');
    if (ogTitleTag) {
      ogTitleTag.setAttribute("content", newTitle);
    } else {
      const newOgTitleTag = document.createElement("meta");
      newOgTitleTag.setAttribute("property", "og:title");
      newOgTitleTag.setAttribute("content", newTitle);
      document.head.appendChild(newOgTitleTag);
    }

    const ogDescriptionTag = document.querySelector('meta[property="og:description"]');
    if (ogDescriptionTag) {
      ogDescriptionTag.setAttribute("content", newDescription);
    } else {
      const newOgDescriptionTag = document.createElement("meta");
      newOgDescriptionTag.setAttribute("property", "og:description");
      newOgDescriptionTag.setAttribute("content", newDescription);
      document.head.appendChild(newOgDescriptionTag);
    }
  }, [location.pathname]);

  return (
    <div className="wrapper">
      <Header />

      <div className="serviceItemsHeader">
        <h1 data-aos="zoom-in">Snow Management</h1>
      </div>
      <div className="serviceItemsMain">
        <h2 data-aos="fade-down">
          "Enhancing the beauty and function of your outdoor space with expert
          snow management."
        </h2>
        <hr />
        <TextCarousel texts={carouselTexts} interval={5000} />
        <h2
          className="section-header text-center pb-4 mb-5"
          data-aos="fade-right"
        >     
          Snow Management Services
        </h2>
        <div className="container mb-5 pb-5">
          <div className="row">
            <div className="hex-cols" data-aos="zoom-in">
              <div className="hexagon-menu">
                <div className="hexagon-item">
                  <div className="hex-item ">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item ">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-content ">
                    <span className="hex-content-inner ">
                      <span className="icon">
                        <i className="fa-solid fa-snowplow"></i>
                      </span>
                      <span className="title">Snow Plowing</span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item ">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item ">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-content ">
                    <span className="hex-content-inner ">
                      <span className="icon">
                        <i className="fa-solid fa-person-digging"></i>
                      </span>
                      <span className="title">
                        Snow Shovelling & Snow Blowing
                      </span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item ">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item ">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-content ">
                    <span className="hex-content-inner ">
                      <span className="icon">
                        <i className="fa-solid fa-snowman"></i>
                      </span>
                      <span className="title">
                        {" "}
                        Salting, Pre & Post Treatments
                      </span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item ">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item ">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-content ">
                    <span className="hex-content-inner ">
                      <span className="icon">
                        <i className="fa-regular fa-clock"></i>
                      </span>
                      <span className="title">24/7 Response</span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item ">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item ">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-content ">
                    <span className="hex-content-inner ">
                      <span className="icon">
                        <i className="fa-solid fa-calendar-days"></i>
                      </span>
                      <span className="title">Seasonal Contracts</span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item ">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item ">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-content ">
                    <span className="hex-content-inner ">
                      <span className="icon">
                        <i className="fa-solid fa-snowflake"></i>
                      </span>
                      <span className="title">Snow & Weather Monitoring </span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item ">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item ">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-content ">
                    <span className="hex-content-inner ">
                      <span className="icon">
                        <i className="fa-solid fa-circle-check"></i>
                      </span>
                      <span className="title">Insured for Snow Services</span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item ">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item ">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-content ">
                    <span className="hex-content-inner ">
                      <span className="icon">
                        <i className="fa-solid fa-warehouse"></i>
                      </span>
                      <span className="title">Warehousing/ Commercial</span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item ">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item ">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-content ">
                    <span className="hex-content-inner ">
                      <span className="icon">
                        <i className="fa-solid fa-house-circle-check"></i>
                      </span>
                      <span className="title">Residential Homes & Strata</span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* -------------- serviceImages SECTION -------------*/}
        {/* -------------- BANNER SECTION -------------*/}
        <div className="banner-section">
          <div className="container">
            <div className="row no-gutters">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <h3 className="heading" data-aos="fade-right">
                  "Empowering Progress, Igniting <span> Results!" </span>
                </h3>
              </div>
              <div
                className="col-lg-6 col-md-12 col-sm-12"
                data-aos="fade-left"
              >
                <p className="text">
                  "Revitalize your outdoor space with our expert landscaping
                  maintenance services, ensuring a thriving and picturesque
                  environment year-round."
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* -------------- about SECTION -------------*/}
        <section className="section-container">
          <div className="image-container px-5" data-aos="fade-right">
            <img
              src={snowCoveredStairsWalkway}
              alt="Snow-covered stairs and walkway with icy conditions at night"
              title="Snow Removal for Safe Access: Stairs and Walkways"
              className="winterSafetyImg"
            />
          </div>
          <div className="text-container px-5" data-aos="fade-up">
            <span className="caption d-block mb-2 font-weight-bold">
              Outstanding Services
            </span>
            <h3 className="headingImageText text-uppercase ">
              Snow Management
            </h3>
            <p>
              Reliable Snow Management at your service! BUR-HAN is proud to
              offer full snow management service contracts for the areas of
              Vancouver, Burnaby, North Vancouver & West Vancouver. We're here
              around the clock when winter strikes to ensure the safety and
              accessibility of your site with our friendly and dedicated team.
            </p>
          </div>
        </section>
        <section className="section-container">
          <div className="text-container px-5" data-aos="fade-up">
            <h3 className="headingImageText text-uppercase">
              Why choose BUR-HAN for your
              <br /> snow management needs?
            </h3>
            <ul>
              <li>
                <strong>Live Monitoring:</strong> We keep a close eye on the
                weather so you don't have to! Our crews are ready to dispatch at
                a moments notice.{" "}
              </li>
              <li>
                {" "}
                <strong>Timely Billing:</strong> All invoices are sent out in a
                timely manner and include a copy of the weather networks
                predicted forecast so you have copies of what the weather was
                calling for during each salting/snow clearing service.{" "}
              </li>
              <li>
                {" "}
                <strong> Insured & Bonded:</strong> Feel safe with us! We are
                fully ensured and bonded for your protection.{" "}
              </li>
            </ul>
          </div>
          <div className="image-container px-5" data-aos="fade-left">
            <img
              src={snowplowClearingParkingLot}
              alt="Snowplow clearing a large parking lot during heavy snowfall at night"
              title="Efficient Parking Lot Snow Clearing Services"
              className="snowRemovalImg"
            />
          </div>
        </section>
        {/* -------------- END OF  -------------*/}
        <div className="secondBanner">
          <div className="container" data-aos="fade-down">
            <div className="row">
              <div className="col-md-6 ">
                <h3>Ready to elevate your outdoor experience?</h3>
                <p>
                  Fill out our Get a Quote page, and let our expert landscaping
                  team bring your vision to life with a personalized estimate
                  tailored to your unique preferences and needs.
                </p>
              </div>
              <div className="col-md-6 ">
                <div className="call-to-action text-right">
                  <a href="/contact-us" className="get-a-quote">
                    Get A Quote
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
